import DefaultLayout from '../layouts/DefaultLayout';
import AccountLayout from '../layouts/AccountLayout';

export default [
    {
        path: '/',
        name: 'Home',
        components: {
            layout:         DefaultLayout,
            content:        () => import(/* webpackChunkName: "homepage" */ '../views/Home')
        },
        meta: {
            preserveQueryParams: true,
        }
    },
    {
        path: '/vehicles',
        name: 'Vehicles',
        components: {
            layout:         DefaultLayout,
            content:        () => import(/* webpackChunkName: "vehicles" */ '../views/Vehicles/Index.vue')
        },
        meta: {
            preserveQueryParams: true,
        }
    },
    {
        path: '/vehicles/:id/reserve',
        name: 'Reserve',
        components: {
            layout:         DefaultLayout,
            content:        () => import(/* webpackChunkName: "vehicles" */ '../views/Vehicles/Show.vue')
        },
        meta: {
            requiresAuth: true,
            preserveQueryParams: true,
        }
    },
    {
        path: '/login',
        name: 'LoginPage',
        components: {
            layout:         DefaultLayout,
            content:        () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue')
        },
        meta: {}
    },
    {
        path: '/forgot-password',
        name: 'ForgotPasswordPage',
        components: {
            layout:         DefaultLayout,
            content:        () => import(/* webpackChunkName: "forgot-password" */ '../views/Auth/ForgotPassword.vue')
        },
        meta: {}
    },
    {
        path: '/reset-password',
        name: 'ResetPasswordPage',
        components: {
            layout:         DefaultLayout,
            content:        () => import(/* webpackChunkName: "reset-password" */ '../views/Auth/ResetPassword.vue')
        },
        meta: {}
    },
    {
        path: '/verify-email',
        name: 'VerifyEmailPage',
        components: {
            layout:         DefaultLayout,
            content:        () => import(/* webpackChunkName: "verify-email" */ '../views/Auth/VerifyEmail.vue')
        },
        meta: {}
    },
    {
        path: '/register',
        name: 'RegisterPage',
        components: {
            layout:         DefaultLayout,
            content:        () => import(/* webpackChunkName: "register" */ '../views/Auth/Register.vue')
        }
    },
    {
        path: '/account',
        name: 'Account',
        components: {
            layout:         AccountLayout,
            content:        () => import(/* webpackChunkName: "account" */ '../views/Account/Reservations/Index.vue')
        },
        meta: {
            requiresAuth:           true,
            preserveQueryParams:    true,
        },
    },
    {
        path: '/account/settings',
        name: 'AccountSettings',
        meta: {
            requiresAuth:           true,
            preserveQueryParams:    true,
        },
        components: {
            layout:     AccountLayout,
            content:    () => import(/* webpackChunkName: "account-settings" */ '../views/Account/Settings/Index.vue')
        },
    },
    {
        path: '/account/security',
        name: 'AccountSecurity',
        meta: {
            requiresAuth:           true,
            preserveQueryParams:    true,
        },
        components: {
            layout:     AccountLayout,
            content:    () => import(/* webpackChunkName: "account-security" */ '../views/Account/Security/Index.vue')
        },
    },
    {
        path: '/account/reservations',
        name: 'AccountReservations',
        meta: {
            requiresAuth:           true,
            preserveQueryParams:    true,
        },
        components: {
            layout:     AccountLayout,
            content:    () => import(/* webpackChunkName: "reservations" */ '../views/Account/Reservations/Index.vue')
        },
    },
    {
        path: '/account/receipts',
        name: 'AccountReceipts',
        meta: {
            requiresAuth:           true,
            preserveQueryParams:    true,
        },
        components: {
            layout:     AccountLayout,
            content:    () => import(/* webpackChunkName: "receipts" */ '../views/Account/Receipts/Index.vue')
        },
    }
]
