import Vue              from 'vue';
import secureStorage    from '../../plugins/secureStorage';
import { 
    login               as requestLogin, 
    register            as requestRegister,
    forgotPassword      as requestForgotPassword,
    resetPassword       as requestResetPassword,
    changePassword      as requestChangePassword,
    deleteAvatar        as requestDeleteAvatar,
    updateAvatar        as requestUpdateAvatar,
    verifyEmail         as requestVerifyEmail,
}                       from '../../api/auth';


export default {
    login({ commit }, user) {
        return new Promise((resolve, reject) => {
            commit('AUTH_ATTEMPTING')
            requestLogin(user)
                .then(resp => {
                    const token = resp.data.token
                    const user = resp.data.user

                    secureStorage.setItem('token', token)
                    secureStorage.setItem('user', user)
                    Vue.prototype.$http.defaults.headers.common['Authorization'] = token
                    commit('AUTH_SUCCESS', { token: token, user: user })
                    resolve(resp)
                })
                .catch(err => {
                    commit('AUTH_ERROR')
                    secureStorage.removeItem('token')
                    reject(err)
                })
        })
    },
    register({ commit }, user) {
        return new Promise((resolve, reject) => {
            commit('AUTH_ATTEMPTING')
            requestRegister(user)
                .then(resp => {
                    const token = resp.data.token
                    const user = resp.data.user

                    secureStorage.setItem('token', token)
                    secureStorage.setItem('user', user)
                    Vue.prototype.$http.defaults.headers.common['Authorization'] = token
                    commit('AUTH_SUCCESS', { token: token, user: user })
                    resolve(resp)
                })
                .catch(err => {
                    commit('AUTH_ERROR', err)
                    secureStorage.removeItem('token')
                    reject(err)
                })
        })
    },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            commit('AUTH_CLEAR')
            secureStorage.removeItem('token')
            secureStorage.removeItem('user')
            delete Vue.prototype.$http.defaults.headers.common['Authorization']
            resolve()
        })
    },
    forgotPassword({ commit }, data) {
        return new Promise((resolve, reject) => {
            requestForgotPassword(data)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    resetPassword({ commit }, data) {
        return new Promise((resolve, reject) => {
            requestResetPassword(data)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    verifyEmail({ commit }, url) {
        return new Promise((resolve, reject) => {
            requestVerifyEmail(url)
                .then(resp => {
                    if (resp.status === 202) {                        
                        commit('VERIFIED_EMAIL', true);
                    }
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    changePassword({commit}, data) {
        return new Promise((resolve, reject) => {
            requestChangePassword(data)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        });
    },
    deleteAvatar({ commit }, user) {
        return new Promise((resolve, reject) => {
            requestDeleteAvatar(user)
                .then(resp => {
                    const user = resp.data.user

                    secureStorage.setItem('user', user)
                    commit('AVATAR_DELETE_SUCCESS', { user: user })
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    updateAvatar({ commit }, data) {
        return new Promise((resolve, reject) => {
            requestUpdateAvatar(data)
                .then(resp => {
                    const user = resp.data.user

                    secureStorage.setItem('user', user)
                    commit('AVATAR_CHANGE_SUCCESS', { user: user })
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}