import Vue              from 'vue'
import VueI18n          from 'vue-i18n'
import messages         from './messages'
import urlHelper        from '../util/url';
import secureStorage    from "../plugins/secureStorage";

Vue.use(VueI18n)

var searchObj = urlHelper.extractQuery();

export default new VueI18n({
    locale: searchObj["lang"] || secureStorage.getItem('locale') || 'ar',
    fallbackLocale: 'ar',
    messages
})
