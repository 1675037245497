<template>
  <div>
    <v-img
      :src="loadImage"
      :key="imageComponentKey"
      class="image"
      v-bind="imageAttrs"
      @error="onError"
    >
      
    </v-img>
  </div>
</template>

<script>

export default {
  props: {
    sourceLink: {
      type: String,
      default: null,
    },
    imageAttrs: {
      type: Object,
      required: false,
    },
    isFromAssets: {
      type: Boolean,
      required: false,
      default: true
    },
    fallbackImage: {
      type: String,
      required: false,
      default: null
    },
  },
  data() {
    return {
      showCallBackImage: false,
      imageComponentKey: 0,
    };
  },
  computed: {
    loadImage() {
      if (!this.sourceLink || this.showCallBackImage) {
        if (this.fallbackImage)
          return this.fallbackImage;
        return this.getCompanyLogoURL(true);
      }

      let imageUrl = process.env.VUE_APP_DASHBOARD_API;

      if (this.isFromAssets) {
        imageUrl = imageUrl + 'assets?src=';
      }
      imageUrl = imageUrl + this.sourceLink.replaceAll('\\', '/');

      return imageUrl;
    },
  },
  methods: {
    onError(e) {
      this.showCallBackImage = true;
    },
  },
};
</script>

<style scoped lang="scss">
.image {
  height: 100%;
}
.callback-image {
  // width: 100%;
  margin: 0 auto;
  display:block;
}
</style>