import {
    show as requestShow,
} from '../../api/companies';


export default {
    show({ state, commit }, filter = {}) {
        return new Promise((resolve, reject) => {

            if (state.company !== null) {
                return state.company;
            }

            if (state.promise) {
                return state.promise;
            }

            let promise = requestShow(filter)
                .then(resp => {
                    const company = resp.data;
                    commit('FETCHED_COMPANY', { company })
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })

            commit('SET_PROMISE', promise);
            return promise;
        })
    },
}