import axios from 'axios';

async function get(filter = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`countries`, {...filter})
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function getStates(filter) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`states`, {params:{...filter}})
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function getCities(filter = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`cities`, {params:{...filter}})
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

export {
    get,
    getStates,
    getCities,
}