import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from '../i18n/i18n';

Vue.use(Vuetify);

export default new Vuetify({
    rtl: i18n.locale == 'ar',
    theme: {dark: false}
});
