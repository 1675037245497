const getClientCoords = () => {
  return new Promise((resolve, reject) =>
    window.navigator.permissions
      ? // Permission API is implemented
        window.navigator.permissions
          .query({
            name: "geolocation",
          })
          .then((permission) =>
            // is geolocation granted?
            permission.state === "granted"
              ? window.navigator.geolocation.getCurrentPosition((pos) =>
                  resolve(pos.coords)
                )
              : resolve(null)
          )
      : // Permission API was not implemented
        reject(new Error("Permission API is not supported"))
  );
}

export default {
    getClientCoords,
}