import { 
    get               as getCurrencies, 
}                       from '../../api/currencies';


export default {
    get({ commit }, user) {
        return new Promise((resolve, reject) => {
            getCurrencies(user)
                .then(resp => {
                    const currencies = resp.data;
                    commit('FETCH_CURRENCIES_SUCCESS', { currencies })
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}