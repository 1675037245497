const differenceInDays = (date1, date2) => {
    const d1 = new Date(date1)
    const d2 = new Date(date2)
    return Math.ceil(Math.abs(d2 - d1) / (1000 * 60 * 60 * 24))
}

const formatDate = (date) => {

    if (!date) return undefined;

    date = new Date(date);

    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return year + "-" + month + "-" + day;
}

const formatTime = (date) => {

    if (!date) return undefined;

    date = new Date(date);

    let hours = date.getHours();
    let minutes = date.getMinutes();

    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + ':' + minutes;
}

const formatDateTime = (date) => {
    if (!date)
        return undefined;

    date = new Date(date);

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    minutes = minutes < 10 ? "0" + minutes : minutes;
    hours = hours < 10 ? "0" + hours : hours;

    return year + "-" + month + "-" + day + "T" + hours + ':' + minutes + ':00';
}

const addDaysToDate = (currentDate, days) => {
    const date = new Date(currentDate);
    date.setDate(date.getDate() + days);
    return date;
}

const getDates = (startDate, stopDate) => {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate = addDaysToDate(currentDate, 1);
    }
    return dateArray;
}

export default {
    differenceInDays,
    formatDate,
    addDaysToDate,
    getDates,
    formatTime,
    formatDateTime
}