<template>
  <div class="polygon-background" :style="styles">
    <div v-for="i in 10" :key="i" class="polygon-row">
      <div v-for="j in 18" :key="j" class="hexagon" :class="{'animate-hex': animateHexagons}"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PolygonBackground",
  props: {
    styles: {
      type: Object,
      default: {},
    },
    animateHexagons: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped>
.polygon-background {
  overflow: hidden;
  background: black;
  z-index: -1;
  //   animation: animate 10s linear infinite; // uncomment for rgb coloring
}
@keyframes animate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
.polygon-row {
  display: inline-flex;
  margin-top: -35px;
  overflow: hidden;
}
.polygon-row:nth-child(even) {
  transform: translateX(-50px);
}
#app.v-application--is-rtl {
  .polygon-row:nth-child(even) {
    transform: translateX(50px);
  }
}
.hexagon {
  position: relative;
  background: #111;
  height: 110px;
  width: 100px;
  margin: 1px;
  transition: 2s;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.hexagon::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  background: rgba(255, 255, 255, 0.03);
}
.hexagon:hover {
  transition: 0s;
  background: rgb(231, 20, 20);
}
.hexagon.animate-hex {
  animation: rotateY 2s ease forwards;
}
@keyframes rotateY {
  0%    { transform: rotateY(0deg); }
  100%  { transform: rotateY(180deg); }
}
</style>