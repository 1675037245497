<template>
  <v-list>
    <v-list-item v-if="!isLoggedIn"  @click="goTo('Account')">
      <v-list-item-icon>
        <v-icon>mdi-account</v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        <v-btn plain depressed text>
          <span v-if="!isLoggedIn" class="subtitle-2">
            {{ $t("Account") }}
          </span>
          <span v-else class="subtitle-2">
            {{ user.name }}
          </span>
        </v-btn>
      </v-list-item-title>
    </v-list-item>
    <v-list-item v-if="isLoggedIn" @click="logout">
      <v-list-item-icon>
        <v-icon>mdi-power</v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        <v-btn
          depressed
          color="darken-3"
          @click="logout"
          class="logout-btn"
        >
          <span class="subtitle-2">
            {{ $t("Logout") }}
          </span>
        </v-btn>
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import Vue            from 'vue';
import { mapGetters } from "vuex";
export default {
  name: "AccountPanel",
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters({
      isLoggedIn:         "auth/isLoggedIn",
      isMobile:           "isMobile",
      isDesktop:          "isDesktop",
      user:               "auth/getUser",      
    }),
  },
  methods: {
    logout: function () {
      this.$store.dispatch("auth/logout");
      this.$router.push('/login')
    },
    goTo(routeName) {
      var searchObj = Vue.urlHelper.extractQuery();
      if (searchObj["company_id"]) {
        this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
      } else {
        this.$router.push({name: routeName});
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>