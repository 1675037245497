import {
    get         as requestGet,
    getCities   as requestGetCities,
    getStates   as requestGetStates, 
}               from '../../api/countries';


export default {
    get({ state, commit }, filter = {}) {
        return new Promise((resolve, reject) => {

            if (state.countries.length) {
                return state.countries;
            }

            if (state.countriesPromise) {
                return state.countriesPromise;
            }

            let promise = requestGet(filter)
                .then(resp => {
                    const countries = resp.data.data;
                    commit('FETCHED_COUNTRIES', { countries })
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })

            commit('SET_COUNTRIES_PROMISE', promise);
            return promise;
        })
    },
    getStates({ state, commit }, filter = {}) {
        return new Promise((resolve, reject) => {

            // if (state.states.length) {
            //     return state.states;
            // }

            // if (state.statesPromise) {
            //     return state.statesPromise;
            // }
            let promise = requestGetStates(filter)
                .then(resp => {
                    const states = resp.data.data;
                    commit('FETCHED_STATES', states)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })

            commit('SET_STATES_PROMISE', promise);
            return promise;
        })
    },
    getCities({ state, commit }, filter = {}) {
        return new Promise((resolve, reject) => {

            // if (state.cities.length) {
            //     return state.cities;
            // }

            // if (state.citiesPromise) {
            //     return state.citiesPromise;
            // }

            let promise = requestGetCities(filter)
                .then(resp => {
                    const cities = resp.data.data;
                    commit('FETCHED_CITIES',cities)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })

            commit('SET_CITIES_PROMISE', promise);
            return promise;
        })
    },
}