import i18n from '../i18n/i18n';

const filterRules = () => {
    return {
        required: (value) => {
            if (!value) {
                return i18n.t("FieldRequired");
            }
            return true;
        },
        email: (value) => {
            if (value) {
                let emailExpr =
                    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
                if (!value.match(emailExpr)) {
                    return i18n.t("FieldMustBeEmail");
                }
                return true;
            }
            return true;
        },
        min8: (value) => {
            if (value && value.length < 8) {
                return i18n.t('AtLeast8CharactersLong');
            }
            return true;
        },
        englishText: (value) => {            
            return /^[A-Za-z]+$/.test(value);
        }
    }
}

export default {
    filterRules
}