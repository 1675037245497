import axios from 'axios';

async function show(id) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`companies/${id}`)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

function getByDomain(domain = null) {
    return axios({
        method:'get',
        url:`company/domain?domain=${domain ? domain : window.location.host}`,
        baseURL: process.env.VUE_APP_DASHBOARD_API,
    })
    .then(res => {
        return res && res.data ? res.data : null;
    });
}

export {
    show,
    getByDomain
}