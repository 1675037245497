export default {
    FETCHED_COUNTRIES(state, payload) {
        state.countries = payload.countries
    },
    SET_COUNTRIES_PROMISE(state, payload) {
        state.countriesPromise = payload;
    },
    FETCHED_STATES(state, payload) {
        state.states = payload
    },
    SET_STATES_PROMISE(state, payload) {
        state.statesPromise = payload;
    },
    FETCHED_CITIES(state, payload) {
        state.cities = payload
    },
    SET_CITIES_PROMISE(state, payload) {
        state.citiesPromise = payload;
    }
}