var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[(!_vm.isEmbed)?_c('nav-bar'):_vm._e(),_c('v-main',{staticClass:"content"},[_vm._t("default"),_c('v-alert',{staticClass:" mb-7 elevation-5 rounded-0 global-alert",attrs:{"max-width":"400","dense":"","type":"info","color":"success","value":_vm.infoAlert,"transition":"scroll-y-reverse-transition","border":"bottom"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]),_c('v-alert',{staticClass:" mb-7 elevation-5 rounded-0 global-alert",attrs:{"max-width":"400","dense":"","type":"error","value":_vm.errorAlert,"transition":"scroll-y-reverse-transition","border":"bottom"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]),_c('v-alert',{staticClass:" mb-7 elevation-5 rounded-0 global-alert",attrs:{"max-width":"400","dense":"","type":"success","value":_vm.successAlert,"transition":"scroll-y-reverse-transition","border":"bottom"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]),_c('account-floating-button',{attrs:{"btn-attrs":{
        fixed: true, 
        right: true, 
        bottom: true,
        large: true,
        iconClass: 'mdi-account',
        color: '#4f7d9c',
        fab: true,
        dark: true,
      }},on:{"clicked":_vm.onProfileFltBtnClick}}),(_vm.isLoggedIn && _vm.profilePopupVisible)?_c('profile-popup',{on:{"profile-closed":function($event){_vm.profilePopupVisible = false}}}):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.loginFormVisible),callback:function ($$v) {_vm.loginFormVisible=$$v},expression:"loginFormVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5  mb-10 red"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("Login"))+" ")])]),_c('v-card-text',[_c('login-form',{attrs:{"solo":false},on:{"successfull-login":function($event){(_vm.profilePopupVisible = true, _vm.loginFormVisible = false)}}})],1)],1)],1)],2),(!_vm.isEmbed)?_c('footer',{staticClass:"footer fixed-bottom"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row footer-row"},[_c('div',{staticClass:"powered-by-content",attrs:{"dir":"ltr"}},[_vm._v(" Copyrights Reserved © "+_vm._s(new Date().getFullYear())+". Powered by ")]),_vm._m(0),_vm._m(1)])])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{attrs:{"href":"https://sawaftech.com","target":"_blank"}},[_c('img',{staticClass:"sawaftech-logo",attrs:{"src":require("../assets/sawaf-logo-white.png"),"alt":"SawafTech"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"payment-verified-image",attrs:{"src":require("../assets/payment-verified.png")}})])}]

export { render, staticRenderFns }