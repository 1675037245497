import Vue              from 'vue'

import dateHelpers      from "./date";
import objectHelpers    from "./object";
import geolocation      from "./geolocation";
import url              from "./url";
import currency         from "./currency";
import form             from "./form";
import string           from "./string";
import file             from "./file";
import recaptcha        from "./recaptcha";

export default {
    install: () => {
        Vue.prototype.dateHelpers       = dateHelpers
        Vue.dateHelpers                 = dateHelpers

        Vue.prototype.objectHelpers     = objectHelpers
        Vue.objectHelpers               = objectHelpers

        Vue.prototype.geolocation       = geolocation
        Vue.geolocation                 = geolocation

        Vue.prototype.urlHelper         = url
        Vue.urlHelper                   = url

        Vue.prototype.currencyHelper    = currency
        Vue.currencyHelper              = currency

        Vue.prototype.formHelper        = form;
        Vue.formHelper                  = form;

        Vue.prototype.stringHelper      = string;
        Vue.stringHelper                = string;

        Vue.prototype.fileHelper        = file;
        Vue.fileHelper                  = file;

        Vue.prototype.recaptcha         = recaptcha;
        Vue.recaptcha                   = recaptcha;
    }
}
