<template>
    <div id="profile-popup" v-if="popupVisible">
        <profile-form @profile-updated="onProfileUpdated" @profile-error="onProfileError">
            <template v-slot:header-actions>
                <v-btn color="blue-grey lighten-4" class="black--text mr-2 ml-2" @click="onProfilePopupClose">
                    {{ $t('Close') }}
                </v-btn>
            </template>
        </profile-form>           
    </div>
</template>

<script>
import ProfileForm from "../../views/Account/Settings/components/ProfileForm";

export default {
    name: "ProfilePopup",
    components: {
        ProfileForm
    },
    data() {
        return {
            popupVisible: true
        }
    },
    methods: {
        onProfileUpdated(payload) {
            this.$emit('profile-upated', payload);
        },
        onProfileError(payload) {
            this.$emit('profile-error', payload);
        },
        onProfilePopupClose() {
            this.popupVisible = false
            this.$emit('profile-closed');
        }
    }
}
</script>
<style scoped lang="scss">
    #profile-popup {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;        
        background: rgba(0,0,0,.5);
        padding: 5vh 5vw;
        animation: fadeIn .5s ease forwards;
        overflow: auto;
    }
</style>