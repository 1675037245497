import axios from 'axios';

async function get(filter = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`currencies`, {...filter})
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

export {
    get
}