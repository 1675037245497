import { 
    show                as showRequest,
    update              as updateRequest,
    deleteSignature     as deleteSignatureRequest,
    deleteDocument      as deleteDocumentRequest,
    addDocument         as addDocumentRequest,
    downloadDocument    as downloadDocumentRequest
}                       from '../../api/profile';

const isProfileComplete = (profile) => {
    const { 
      name, 
      email, 
      phone, 
      license_number,
      licsene_type,
      license_expiry
    } = profile

    return  name            !== null  && 
            email           !== null  && 
            phone           !== null  && 
            license_number  !== null  &&
            licsene_type    !== null  &&
            license_expiry  !== null;
}

export default {
    show({ rootState, commit }) {

        //prevent request if user isn't authenticated
        if (rootState.auth.token == '') {
            return;
        }
        
        return new Promise((resolve, reject) => {
            showRequest()
                .then(resp => {
                    const data = resp.data;

                    var complete = false;
                    if (isProfileComplete(data)) complete = true;
                    commit('PROFILE_COMPLETED', complete);

                    commit('FETCHED_PROFILE', { data })
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    update({ rootState, commit }, data) {

        //prevent request if user isn't authenticated
        if (rootState.auth.token == '') {
            return;
        }

        return new Promise((resolve, reject) => {
            updateRequest(data)
                .then(resp => {

                    const data = resp.data

                    var complete = false;
                    if (isProfileComplete(data)) complete = true;
                    commit('PROFILE_COMPLETED', complete);

                    commit('FETCHED_PROFILE', { data })
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    deleteSignature({ rootState, commit }) {

        //prevent request if user isn't authenticated
        if (rootState.auth.token == '') {
            return;
        }

        return new Promise((resolve, reject) => {
            deleteSignatureRequest()
                .then(resp => {
                    commit('DELETED_SIGNATURE')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    deleteDocument({ rootState, commit }, id) {

        //prevent request if user isn't authenticated
        if (rootState.auth.token == '') {
            return;
        }

        return new Promise((resolve, reject) => {
            deleteDocumentRequest(id)
                .then(resp => {
                    commit('DELETED_DOCUMENT', id)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    addDocument({ rootState, commit }, data) {

        //prevent request if user isn't authenticated
        if (rootState.auth.token == '') {
            return;
        }

        return new Promise((resolve, reject) => {
            addDocumentRequest(data)
                .then(resp => {
                    const data = resp.data;
                    commit('ADDED_DOCUMENT', { data })
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    downloadDocument({ rootState, commit}, id) {

        //prevent request if user isn't authenticated
        if (rootState.auth.token == '') {
            return;
        }

        return new Promise((resolve, reject) => {
            downloadDocumentRequest(id)
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}