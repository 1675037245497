const loadFile = (file) => {
    const fileReader = new FileReader()

    return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
            reject(new DOMException('Problem parsing image file'))
        }
        fileReader.onload = () => {
            resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
    })
}

const base64toBlob = (base64Data, contentType) => {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = Buffer.from(base64Data, 'base64');
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset][0];
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

const dataURLtoFile = (dataurl, filename) => {
    if (dataurl) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    return '';
}

export default {
    loadFile,
    base64toBlob,
    dataURLtoFile
}