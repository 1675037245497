export default {
    AUTH_ATTEMPTING(state) {
        state.status = 'loading'
    },
    AUTH_SUCCESS(state, payload) {
        state.status = 'success'
        state.token = payload.token
        state.user = payload.user
    },
    AUTH_ERROR(state) {
        state.status = 'error'
    },
    AUTH_CLEAR(state) {
        state.status = ''
        state.token = ''
        state.profile = [];
        state.is_profile_complete = false;
    },
    AVATAR_CHANGE_SUCCESS(state, payload) {
        state.user = payload.user
    },
    AVATAR_DELETE_SUCCESS(state, payload) {
        state.user = payload.user
    },
    VERIFIED_EMAIL(state, payload) {
        state.verified = payload;
    }
}