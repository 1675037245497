export default {
    Homepage: "Ev",
    Vehicle: "Araç | Araçlar",
    FindTheSuitableCarAndGetOnRoad: "Uygun Arabayı Bulun ve Yola Çıkın",
    ChooseReservationStartandEndDates: "Rezervasyon Başlangıç ve Bitiş Tarihlerini Seçin",
    Search: "Arama",
    FindVehicle: "Arama Araç",
    ReceivingDate: "Tarih almak",
    DeliveringDate: "Teslim Tarihi",
    VehicleRentalServices: "Araç Kiralama Hizmetleri",
    LoadMore: "Daha Fazla Göster",
    ReserveNow: "Şimdi Rezerve Edin",
    Filter: "Filtre",
    Apply: "Uygulamak",
    ReservationDate: "Rezervasyon Tarihi",
    NumberOfSeats: "Koltukların Sayısı",
    NumberOfPackages: "Paketlerin Sayısı",
    VehicleType: "Araç Tipi",
    VehicleTransmission: "Araç Şanzıman",
    VehicleBrand: "Araç Markası",
    NoVehiclesFound: "Araç bulunamadı. Başlamak için arama filtrelemeyi seçin.",
    ShowingVehiclesBetween: "Aradaki mevcut araçlar gösteriliyor",
    And: "ve",
    PerDay: "Günlük",
    Price: "Fiyat",
    ErrorFetchingData: "Veri alınırken hata oluştu. Lütfen daha sonra tekrar deneyin veya bu hatayı bildirin",
    SearchAndRentAVehicle: "Ara ve Araç Kirala",
    ReservationConfirmation: "Rezervasyon Onayı",
    ViewReservationInformationAndConfirm: "Lütfen aşağıdaki rezervasyon bilgilerini görüntüleyin ve onaylayın. O zaman birkaç dakika içinde sizinle iletişime geçeceğiz",
    NoMoreVehicles: "Artık Araç Yok",
    NumberOfDays: "Gün Sayısı",
    PricesMightBeSubjectToTaxes: "Fiyatlar Vergilere Tabi Olabilir",
    WeJustNeedToKnowWhoYouAre: "Sadece Kim Olduğunuzu Bilmemiz Gerekiyor!",
    FieldRequired: 'Bu alan gereklidir',
    FieldMustBeEmail: 'Lütfen geçerli bir e-posta adresi girin',
    FullName: "Ad Soyad",
    FirstName: 'İlk adı',
    Phone: "Telefon",
    Email: "E-posta",
    MissingReservationInformation: "Bazı rezervasyon bilgileri eksik, lütfen rezervasyonu yeniden gönderin",
    ReservationInformation: "Rezervasyon Bilgileri",
    YourInformation: "Bilgin",
    VehicleInformation: "Araç bilgisi",
    Confirm: "Onaylamak",
    Cancel: "İptal",
    ConfirmReservation: "Rezervasyonu onayla",
    ReserveVehicle: "Yedek Araç",
    NoDatesSelected: "Teslim alma ve teslimat için bir tarih belirlenmedi",
    ThankYouForReservation: "İlk rezervasyon talebiniz gönderildi, rezervasyonunuzu onaylamak için birkaç dakika içinde sizinle iletişime geçeceğiz! Teşekkür ederim.",
    DeliverToDifferentLocation: "Farklı konuma dön",
    ReceivingLocation: "Alış Yeri",
    DeliveringLocation: "Teslim Yeri",
    SearchLocation: "Şehir ara",
    NoCitiesFound: "Şehir bulunamadı.",
    Notes: "Notlar",
    WhenDoYouWantToReserveTheVehicle: "Aracı ne zaman ve nerede rezerve etmek istiyorsunuz?",
    ReceivingAtDate: "Tarihte almak",
    InLocation: "Alanda",
    DeliveringAtDate: "Tarihte teslimat",
    AutomatedManualTransmission: 'Otomatikleştirilmiş Manuel Şanzıman (AMT)',
    Automatic: 'Otomatik',
    Manual: 'Manual / Standart',
    MotorcycleChainDrive: 'Motosiklet - Zincirli Tahrik',
    MotorcycleCVTBeltDrive: 'Motosiklet - CVT Kayış Tahrik',
    MotorcycleShaftDrive: 'Motosiklet - Şaft Tahrik',
    MotorcycleShaftDriveOffRoad: 'Motosiklet - Şaft Tahrikli Off-Road',
    DualClutchTransmission: 'Çift kavramalı Şanzıman (DCT)',
    ElectronicContinuouslyVariable: 'Elektronik Sürekli Değişken (e-CVT)',
    ContinuouslyVariableTransmission: 'Sürekli Değişken Şanzıman (CVT)',
    MotorcycleChainDriveOffRoad: "Motosiklet - Off-Road Sürüş Serisi",
    Convertible: "Çevrilebilir",
    Coupe: "Kup",
    Crossover: "Karşıdan karşıya geçmek",
    Hatchback: "Hatback",
    Limousine: "Limuzin",
    MicroCar: "Mikrokar",
    Minivan: "Minibüs",
    PickupTruck: "Kamyonet",
    Sedan: "Sedan",
    SportsCar: "Spor Araba",
    StationWagon: "İstasyon Vagonu",
    SUV: "SUV",
    Print: "Yazdır",
    ThisReservationRequestIsNotConfirmedYet: 'Bu rezervasyon isteği henüz onaylanmadı.',
    EnterThreeCharactersToSearch: 'Aramaya başlamak için 3 karakter girin..',
    LoginFormHeader: 'Hesabınıza giriş yapın',
    Enter: "Girmek",
    RegisterHere: "Hesabınız yok mu? Buradan kaydolun.",
    Account: 'Hesabım',
    RegisterFormHeader: 'Yeni hesap kaydet',
    Signup: 'Üye olmak',
    LoginHere: "Zaten hesabınız var mı? Giriş yapın.",
    Username: "Kullanıcı adı",
    Password: "Parola",
    PasswordConfirmation: "Şifre onayı",
    PasswordDontMatch: "Şifre eşleşmiyor",
    AtLeast8CharactersLong: "Metin en az 8 karakter olmalıdır.",
    Welcome: 'Hoş geldin',
    Guest: 'Misafir',
    Reservation: 'Rezervasyon | Rezervasyonlar',
    Receipt: 'Makbuz | Gelirler',
    Logout: "Çıkış Yap",
    PersonalInformation: "Kişisel bilgi",
    Residency: "ikametgah",
    DrivingLicense: "Sürücü ehliyeti",
    CreditCard: "Kredi kartı",
    Signature: "İmza",
    InvalidEmailOrPassword: "Geçersiz e-posta/şifre kombinasyonu",
    ForgotPassword: "Parolanızı mı unuttunuz?",
    ResetPassword: "Şifreyi yenile",
    Cancel: "İptal etmek",
    Submit: "Göndermek",
    AccountSecurity: "Hesap Güvenliği",
    ReservationNumber: "Rezervasyon Numarası",
    ClickToView: "Görüntülemek için tıklayın",
    ReservationAddress: "Adres Alma ve Gönderme",
    Receiving: "Alma",
    Delivering: "Teslim",
    Done: "Tamamlandı",
    Pending: "Bekliyor",
    Active: "Aktif",
    Canceled: "İptal edildi",
    Status: "Durum",
    TotalCost: "Toplam tutar",
    Details: "Detaylar",
    CostDetails: 'Maliyet Ayrıntıları',
    Close: "Kapat",
    Discount: "İndirim",
    TaxRate: "Vergi oranı",
    RentalCost: "Kira Maliyeti",
    Tax: "Vergi",
    NoData: "Gösterilecek bilgi yok",
    LoadingData: "Veri yükleniyor...",
    All: "Herşey",
    Of: "Nın-nin",
    RowsPerPage: "Sayfa başına satır",
    ReceiptID: "Makbuz kimliği",
    Date: "Tarih",
    PaymentType: "Ödeme şekli",
    Amount: "Miktar",
    Cash: 'Peşin',
    BankTransfer: 'Banka transferi',
    Type: 'Tip',
    PaymentReceipt: 'Payment Receipt',
    CatchReceipt: 'Fişi yakala',
    NewPassword: "Yeni Şifre",
    OldPasswordIncorrect: "Mevcut şifre geçersiz",
    PasswordChanged: "parola başarıyla değiştirildi",
    PasswordResetLinkSent: "Şifre sıfırlama bağlantısı e-postanıza gönderildi",
    InvalidResetLink: "Geçersiz şifre sıfırlama bağlantısı",
    Address: "Adres",
    Female: "Dişi",
    Male: "Erkek",
    Gender: "Cinsiyet",
    Nationality: "Milliyet",
    BirthDate: "Doğum günü",
    BirthPlace: "Doğum yeri",
    AdditionalPhone: "Ek Telefon",
    SaveEdit: "Düzenlemeleri Kaydet",
    IsForeignResident: "Yabancı Yerleşik?",
    Country: "Ülke",
    State: "Eyalet / Valilik",
    City: "Şehir",
    ResidenceNumber: "İkamet Numarası",
    PassportNumber: "Pasaport numarası",
    DrivingLicenseNumber: "Ehliyet Numarası",
    DrivingLicenseType: "Ehliyet Türü",
    EnglishName: "İngilizce isim",
    CreditCardNumber: "Kredi Kartı Numarası",
    ExpiryDate: "Son kullanma tarihi",
    SecurityCode: "Güvenlik Kodu",
    ProfileUpdated: "Profil başarıyla güncellendi.",
    ErrorProfileUpdate: "Bilgiler güncellenirken hata oluştu, lütfen daha sonra tekrar deneyin",
    SomeFieldsAreInvalid: "Lütfen giriş verilerini kontrol edin. Bazı giriş verileri geçersiz",
    PleaseChooseReservationDateAndLocation: 'Lütfen rezervasyon tarihini ve yerini seçin',
    InvalidToken: "Lütfen yeni bir şifre sıfırlama bağlantısı isteyin.",
    Document: "Belge | Belgeler",
    Add: "Eklemek",
    AddDocument: "Yeni belge ekle",
    Name: "İsim",
    File: "Dosya",
    DocumentDeletedSuccessfully: "Doküman başarıyla silindi",
    DrawNewSignature: "Yeni imza çiz",
    CurrentSignature: "Geçerli imza",
    PleaseCompleteProfileInformation: "Lütfen kişisel bilgilerinizi doldurun (ehliyet verileri ve telefon numarası)",
    OrSimilar: "veya benzeri",
    DailyPrice: "Günlük fiyat",
    TotalPrice: "Toplam fiyat",
    DayOrDays: "Gün(ler)",
    OrderBy: "Tarafından sipariş",
    Desc: "Alçalma",
    Asc: "Yükseliş",
    Class: "Sınıf",
    Direction: "Yön",
    Sort: "Çeşit",
    Company: "Şirket | Şirketler",
    LastName: 'Soy isim',
    ClickToReview:"rezervasyon değerlendirmesi",
    NoNote:"Hesap için not yok.",
    review : "Değerlendirme",
    CannotReviewNow:"şimdi puan veremezsiniz",
    UpdateReview : "Derecelendirme güncellemesi",
    SaveEdits:"Düzenlemeleri Kaydet",
    sortBy:'sıralama ölçütü',
    SignatureDeleted :"İmza silindi",
    Delete:"Silmek",
    Undo:"Geri almak",
    ApplyCoupon: "kuponu onayla",
    "Enter Coupon Code": "Kupon kodunu girin",
    "Enter coupon code first": "Önce kupon kodunu girin",
    "Coupon not found": "Kupon bulunamadı",
    "Coupon applied successfully": "Kupon başarıyla uygulandı!",
    Final: "Final"
}