<template>
  <v-avatar :size="size" class="wrapper" color="red darken-3 ma-2">
    <v-progress-circular class="avatar-loading" v-show="loading" indeterminate color="red" />
    <async-image v-if="user.avatar" :sourceLink="useImagePath" class="image" />
    <span v-else class="text-h5 "> {{ user.name[0] }} </span>
    <div v-if="controls" class="control">
      <v-btn icon color="green" @click="updateAvatar">
        <v-icon>mdi-pen</v-icon>
      </v-btn>
      <v-btn v-show="hasAvatar" icon color="red" @click="deleteAvatar">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
      <input
        ref="uploader"
        class="d-none"
        type="file"
        @change="onFileChanged"
      />
    </div>
  </v-avatar>
</template>

<script>
import { mapGetters } from "vuex";
import AsyncImage from "../Util/AsyncImage.vue";
export default {
  components: { AsyncImage },
  name: "Avatar",
  props: {
    size: {
      type: Number | String,
      default: 100,
    },
    controls: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      controlHidden: true,
      selectedFile: null,
      loading: false,
    };
  },
  computed: {
    hasAvatar() {
      return this.user.avatar;
    },
    ...mapGetters({
      user: "auth/getUser",
    }),
    useImagePath() {
      let url = this.user.avatar.replaceAll('[', '');

      url =url.replaceAll(']', '');
      url =url.replaceAll('"', '');
      
      return url;
    }
  },
  methods: {
    deleteAvatar() {
      this.loading = true;
      this.$store
        .dispatch("auth/deleteAvatar", this.user)
        .then((resp) => {
          his.$emit("success-avatar-remove", true);
        })
        .catch((failure) => {
          if (failure.response) {
            let message = "";
            if (failure.response.data.error) {
              message = failure.response.data.error.message;
            } else {
              message = failure.response.data.message;
            }
            this.$root.$emit("notify-error", this.$t(message));

            this.$emit("failure-avatar-remove", true);
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    updateAvatar() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.loading = true;

      let data = {
        photo: e.target.files[0],
      };

      if (data.photo) {
        this.$store
          .dispatch("auth/updateAvatar", data)
          .then((resp) => {
            his.$emit("success-avatar-change", true);
          })
          .catch((failure) => {
            if (failure.response) {
              let message = "";
              if (failure.response.data.error) {
                message = failure.response.data.error.message;
              } else {
                message = failure.response.data.message;
              }
              this.$root.$emit("notify-error", this.$t(message));

              this.$emit("failure-avatar-change", true);
            }
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 500);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  transition: 0.5s ease;
  &:hover {
    .control {
      opacity: 1;
    }
  }
  .image {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .avatar-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  .control {
    opacity: 0;
    transition: 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    backdrop-filter: blur(1px);
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
</style>