<template>
  <div class="main">
    <nav-bar v-if="!isEmbed" />
    <v-main class="content">
      <slot />
      <v-alert
        max-width="400"
        dense
        type="info"
        color="success"
        :value="infoAlert"
        class=" mb-7 elevation-5 rounded-0 global-alert"
        transition="scroll-y-reverse-transition"
        border="bottom"
      >
        {{ alertMessage }}
      </v-alert>
      <v-alert
        max-width="400"
        dense
        type="error"
        :value="errorAlert"
        class=" mb-7 elevation-5 rounded-0 global-alert"
        transition="scroll-y-reverse-transition"
        border="bottom"
      >
        {{ alertMessage }}
      </v-alert>
      <v-alert
        max-width="400"
        dense
        type="success"
        :value="successAlert"
        class=" mb-7 elevation-5 rounded-0 global-alert"
        transition="scroll-y-reverse-transition"
        border="bottom"
      >
        {{ alertMessage }}
      </v-alert>
      <account-floating-button 
        :btn-attrs="{
          fixed: true, 
          right: true, 
          bottom: true,
          large: true,
          iconClass: 'mdi-account',
          color: '#4f7d9c',
          fab: true,
          dark: true,
        }" 
        @clicked="onProfileFltBtnClick" 
      />
      <profile-popup @profile-closed="profilePopupVisible = false" v-if="isLoggedIn && profilePopupVisible" />
      <v-dialog v-model="loginFormVisible" width="500">
          <v-card>
            <v-card-title class="text-h5  mb-10 red">
              <p> {{ $t("Login") }} </p>
            </v-card-title>

            <v-card-text>
              <login-form 
                  :solo="false"
                  @successfull-login="(profilePopupVisible = true, loginFormVisible = false)"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
    </v-main>
    <footer
      v-if="!isEmbed"
      class="footer fixed-bottom"
    >
    <div class="container">
      <div class="row footer-row">
        <div dir="ltr" class="powered-by-content">
          Copyrights Reserved &copy; {{ new Date().getFullYear() }}.
          Powered by
        </div>
        <div>
          <a href="https://sawaftech.com" target="_blank">
            <img
              class="sawaftech-logo"
              src="../assets/sawaf-logo-white.png"
              alt="SawafTech"
            />
          </a>
        </div>
        <div>
          <img
              class="payment-verified-image"
              src="../assets/payment-verified.png"
            />
        </div>
      </div>
    </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters }         from "vuex";
import NavBar                 from "../components/NavBar";
import ProfilePopup           from "../components/Account/ProfilePopup";
import AccountFloatingButton  from "../components/Account/FloatingButton";
import LoginForm              from "../components/Form/Auth/Login";

export default {
  name: "DefaultLayout",
  props: {},
  data() {
    return {
      infoAlert:            false,
      errorAlert:           false,
      successAlert:         false,
      alertMessage:         "",
      profilePopupVisible:  false,
      loginFormVisible:     false,
    };
  },
  components: {
    NavBar,
    ProfilePopup,
    AccountFloatingButton,
    LoginForm
  },
  computed: {
    ...mapGetters({ 
      isEmbed:      "isEmbed",
      isLoggedIn:   "auth/isLoggedIn",
    }),
  },
  methods: {
    notifyInfo(message) {
      this.alertMessage = message;
      this.flashAlert("infoAlert");
    },
    notifySuccess(message) {
      this.alertMessage = message;
      this.flashAlert("successAlert");
    },
    notifyError(message) {
      this.alertMessage = message;
      this.flashAlert("errorAlert");
    },
    flashAlert(type) {
      this.$data[type] = true;
      setTimeout(() => {
        this.$data[type] = false;
        this.alertMessage = "";
      }, 3000);
    },
    onProfileFltBtnClick() {
      if (this.isLoggedIn) {
        this.profilePopupVisible  = true;
      } else {
        this.loginFormVisible     = true;
      }
    }
  },
  mounted() {
    this.$root.$on("notify-info", (message) => this.notifyInfo(message));
    this.$root.$on("notify-error", (message) => this.notifyError(message));
    this.$root.$on("notify-success", (message) => this.notifySuccess(message));
  },
};
NavBar;
</script>

<style scoped>

.content {
  /* position: fixed; */
  /* margin-top: 4.5rem; */
  width: 100%;
  height: 100%;
}
.global-alert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999;
}
footer {
  background: #000;
}
footer .sawaftech-logo {
  width: 65px;
}
footer .payment-verified-image {
  width: 300px;
}
.footer-row, .footer-row .powered-by-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:#fff
}

.footer-style {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media (max-width: 767px) {
  .footer-row, .footer-row .powered-by-content {
    display: block;
  }
  footer .sawaftech-logo {
    width: 100px;
  }
  footer .payment-verified-image {
    width: 100%;
  }
  .footer-row div {
    text-align: center;
    margin: 10px auto;
  }
}

</style>
