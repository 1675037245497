import { render, staticRenderFns } from "./PolygonBackground.vue?vue&type=template&id=d9d60554&scoped=true&"
import script from "./PolygonBackground.vue?vue&type=script&lang=js&"
export * from "./PolygonBackground.vue?vue&type=script&lang=js&"
import style0 from "./PolygonBackground.vue?vue&type=style&index=0&id=d9d60554&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9d60554",
  null
  
)

export default component.exports