const convert = (amount, fromExchangeRate, fromCurrencyID, toExchangeRate, toCurrencyID) => {

    let convertedAmount = amount; 

    if (fromCurrencyID && toCurrencyID) {
        if (fromCurrencyID == toCurrencyID) {
            return convertedAmount;
        } else {
            convertedAmount *= fromExchangeRate;
            convertedAmount /= toExchangeRate;
            return convertedAmount;
        }
    }
    return convertedAmount /= toExchangeRate;
}

export default {
    convert,
}