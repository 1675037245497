export default {
    FETCHED_PROFILE(state, payload) {
        payload.data.documents.sort((a,b) => {
            return b.id - a.id;
        });
        state.profile = payload.data
    },
    PROFILE_COMPLETED(state, payload) {
        state.is_profile_complete = payload;
    },
    DELETED_DOCUMENT(state, id) {
        state.profile.documents = state.profile.documents.filter(d => d.id !== id);
    },
    ADDED_DOCUMENT(state, payload) {
        state.profile.documents.unshift(payload.data);
    },
    UPLOAD_PROGRESS(state, progress) {
        state.uploadProgress = progress;
    },
    RESET_UPLOAD_PROGRESS(state) {
        state.uploadProgress = 0;
    },
    DELETED_SIGNATURE(state) {
        state.profile.signature = null;
    }
}