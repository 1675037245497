<template>
  <v-app id="app">
    <router-view name="layout">
      <router-view name="content" />
    </router-view>
  </v-app>
</template>

<script>
  export default {
  }
</script>

<style lang="scss">
@import "sass/variables.scss";
@import "sass/globals.scss";

#app {
  font-family: $body-font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app.v-application--is-rtl {
  $body-font-family: "Tajawal", sans-serif;
}

html,
body,
#app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 2;

  background-color: #fff;
  background-blend-mode: multiply;
  background-size: cover;
}
.v-application--wrap {
  min-height: unset !important;
}
</style>
