<template>
  <div>
    <v-btn plain depressed text @click="goTo('Account')">
      <v-icon>mdi-account</v-icon>
      <span v-if="!isLoggedIn" class="subtitle-2">
        {{ $t("Account") }}
      </span>
      <span v-else class="subtitle-2">
        {{ user.name }}
      </span>
    </v-btn>
    <v-btn
      v-if="isLoggedIn"
      depressed
      color="darken-3"
      @click="logout"
      class="logout-btn"
    >
      <v-icon>mdi-power</v-icon>
      <span class="subtitle-2 ">
        {{ $t("Logout") }}
      </span>
    </v-btn>
  </div>
</template>

<script>
import Vue            from 'vue';
import { mapGetters } from "vuex";
export default {
  name: "AccountPanel",
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters({
      isLoggedIn:         "auth/isLoggedIn",
      isMobile:           "isMobile",
      isDesktop:          "isDesktop",
      user:               "auth/getUser",      
    }),
  },
  methods: {
    logout: function () {
      this.$store.dispatch("auth/logout").then(res=>{
        this.$router.replace('/login')
      });
    },
    goTo(routeName) {
        var searchObj = Vue.urlHelper.extractQuery();
        if (searchObj["company_id"]) {
          this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
        } else {
          this.$router.push({name: routeName});
        }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>