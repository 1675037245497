<template>
  <v-card v-bind="cardProps">
    <v-toolbar v-bind="cardToolbarProps">
      {{ cardTitle }}
    </v-toolbar>
    <v-card-text v-bind="cardTextProps">
      <v-row no-gutters>
        <v-col cols="12">
          <v-form v-model="valid" ref="add-document-form">
            <v-text-field
              v-model="name"
              color="dark"
              :label="$t('Name')"
              prepend-icon="mdi-file"
              :rules="[filterRules.required]"
            ></v-text-field>
            <v-file-input
              v-model="file"
              color="dark"
              :label="$t('File')"
              :rules="[filterRules.required]"
            ></v-file-input>
            <v-progress-linear v-if="isUploading" v-model="uploadProgress" color="green" height="25">
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-bind="cardActionsProps">
      <v-spacer v-if="cardActionsProps.hasSpacer"></v-spacer>
      <v-btn v-if="enableSubmit" @click="attemptAdd" v-bind="submitBtnProps" :loading="isUploading">
        <div v-if="submitBtnProps.hasCustomContent">
          {{ submitBtnProps.contentText }}
          <v-icon small class="ma-1">
            {{ submitBtnProps.contentIcon }}
          </v-icon>
        </div>
      </v-btn>
      <slot name="card-actions" />
    </v-card-actions>
  </v-card>
</template>


<script>
import Vue  from "vue";
import i18n from "../../../../i18n/i18n";
import { mapGetters } from 'vuex';

export default {
  name: "AddDocumentForm",
  props: {
    cardTitle: {
      type: String,
      default: i18n.t("AddDocument"),
    },
    cardProps: {
      type: Object,
      default: (_) => {
        return {};
      },
    },
    cardTextProps: {
      type: Object,
      default: (_) => {
        return {
          class: "pa-5",
        };
      },
    },
    cardToolbarProps: {
      type: Object,
      default: (_) => {
        return {
          color: "dark",
          dark: true,
          class: "text-h6",
        };
      },
    },
    cardActionsProps: {
      type: Object,
      default: (_) => {
        return {
          class: "justify-end",
        };
      },
    },
    enableSubmit: {
      type: Boolean,
      default: true,
    },
    submitBtnProps: {
      type: Object,
      default: (_) => {
        return {
          color: "green darken-2 elevation-0",
          dark: true,
          hasCustomContent: true,
          contentText: i18n.t("Add"),
          contentIcon: "mdi-check",
        };
      },
    },
  },
  data() {
    return {
      name: null,
      file: null,
      isUploading: false,
      valid: true,
    };
  },
  computed: {
    filterRules() {
        return Vue.formHelper.filterRules();
    },
    ...mapGetters({
        uploadProgress: "profile/getUploadProgress"
    })
  },
  methods: {
    attemptAdd() {
      // to validate if none of the inputs were changed
      let validation = this.$refs["add-document-form"].validate();
      if (this.valid && validation) {
        this.$emit("attempting-submit", true);
        this.isUploading = true;

        let data = {
          label: this.name,
          file: this.file,
        };

        this.$store
          .dispatch("profile/addDocument", data)
          .then((success) => {
            this.$emit("successfull-upload", true);
          })
          .catch((failure) => {
            if (failure.response) {
              this.$root.$emit(
                "notify-error",
                this.$t(failure.response.data.error.message)
              );
            }
            this.$emit("failure-upload", true);
          })
          .finally(() => {
            setTimeout(() => {
              this.isUploading = false;
            }, 500);
          });
      }
    },
    startPercentageBar() {
        this.uploadingPercentageInterval = setInterval(() => {

        }, 1);
    },
    endPercentageBar() {

    }
  },
};
</script>
