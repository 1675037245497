<template>
  <v-navigation-drawer
    :right="!isRtl"
    v-model="drawer"
    class="acc-nav-bar"
    v-if="isDesktop"
    hide-overlay
  >
    <nav-bar />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import NavBar from "./NavBar";
export default {
  name: "AccountNavDrawer",
  components: {
    NavBar,
  },
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    ...mapGetters({
      isRtl:      "isRtl",
      isMobile:   "isMobile",
      isDesktop:  "isDesktop",
    }),
  },
};
</script>

<style scoped>
  .acc-nav-bar {
    background-color: #EEF0EB
  }
</style>