<template>
  <v-list nav>
    <v-list-item>
      <v-list-item-title class="text-center">
        <Avatar :size="50" />
        <p class="mb-0">{{ user.email }}</p>
        <p>{{ user.name }}</p>
      </v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item @click="goTo('AccountReservations')">
      <v-list-item-icon>
        <v-icon>mdi-car-key</v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        <v-btn depressed text plain class="account-nav-item">
          <span class="subtitle-2">
            {{ $tc("Reservation", 2) }}
          </span>
        </v-btn>
      </v-list-item-title>
    </v-list-item>
    <v-list-item @click="goTo('AccountReceipts')">
      <v-list-item-icon>
        <v-icon>mdi-receipt</v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        <v-btn depressed text plain class="account-nav-item">
          <span class="subtitle-2">
            {{ $tc("Receipt", 2) }}
          </span>
        </v-btn>
      </v-list-item-title>
    </v-list-item>
    <v-list-item @click="goTo('AccountSettings')">
      <v-list-item-icon>
        <v-icon>mdi-account-cog</v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        <v-btn depressed text plain class="account-nav-item">
          <span class="subtitle-2">
            {{ $t("Account") }}
          </span>
        </v-btn>
      </v-list-item-title>
    </v-list-item>
    <v-list-item @click="goTo('AccountSecurity')">
      <v-list-item-icon>
        <v-icon>mdi-shield-account</v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        <v-btn depressed text plain class="account-nav-item">
          <span class="subtitle-2">
            {{ $t("AccountSecurity") }}
          </span>
        </v-btn>
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Avatar from "../Form/Avatar.vue";
export default {
  components: { Avatar },
  name: "AccountNavBar",
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  methods: {
    goTo(routeName) {
      var searchObj = Vue.urlHelper.extractQuery();
      if (searchObj["company_id"]) {
        this.$router.push({
          name: routeName,
          query: { company_id: searchObj["company_id"] },
        });
      } else {
        this.$router.push({ name: routeName });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account-nav-item {
  &:hover {
    color: #ff4f4f;
  }
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
</style>
