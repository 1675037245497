const clean = (obj) => {
    let c = obj;
    for (var p in c) {
        if (c[p] === null || c[p] === undefined || c[p] === " " || c[p] === "") {
            delete c[p];
        }
    }
    return c;
}

const formalize = (object, formData, namespace) => {

    let fd = formData || new FormData();
    let formKey;

    for (let property in object) {
        if (object.hasOwnProperty(property) && (object[property] !== null)) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
                formalize(object[property], fd, formKey);
            } else if (typeof object[property] === 'boolean') {
                if (object[property] == 'true') {
                    fd.append(formKey, 1);
                } else {
                    fd.append(formKey, 0);
                }
                // fd.append(formKey, JSON.stringify(object[property]));
                // fd.append(formKey, object[property]);
            } else {
                fd.append(formKey, object[property]);
            }
        }
    }

    return fd
}

export default {
    clean,
    formalize
}