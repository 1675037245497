<template>
  <v-progress-circular
    indeterminate
    :color="color"
    :size="size"
    class="text-center"
  >
    <img width="75%" :src="getCompanyLogoURL(true)" alt="" />
  </v-progress-circular>
</template>

<script>
export default {
  name: "DevbeyLogoLoader",
  props: {
    size: {
      type: Number|String,
      default: 200
    },
    color: {
      type: String,
      default: 'red'
    }
  }
};
</script>
