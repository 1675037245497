const toSentenceCase = (snakeCase) => {
    let sentence = snakeCase.toLowerCase().split("_");
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join("");
}

const capitalize = (string) => {
    return string[0].toUpperCase() + string.substring(1);
}

export default {
    toSentenceCase,
    capitalize
}
