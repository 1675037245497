import Vue              from 'vue'
import App              from './App.vue'
import router           from './router'
import store            from './store'
import vuetify          from './plugins/vuetify'
import axios            from 'axios';
import i18n             from "./i18n/i18n";
import util             from "./util";
import DatetimePicker   from 'vuetify-datetime-picker'
import secureStorage    from "./plugins/secureStorage";
import VueSignaturePad  from 'vue-signature-pad';

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_DASHBOARD_URL;
axios.interceptors.request.use(function (config) {
    //intercept all requests to add backend api compatible headers
    config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    const token = secureStorage.getItem('token')
    if (token) {
        config.headers = {
            ...config.headers,
            Authorization: 'Bearer ' + token
        }
    }

    return config;
});
axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
      if (error.response.status == 403 || error.response.status == 401) {
        var searchObj = Vue.urlHelper.extractQuery()
        store.dispatch("auth/logout").then(() => {
            if (searchObj["company_id"]) {
                this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
            } else {
                this.$router.push({name: routeName});
            }
          })
      }
    return Promise.reject(error);
  });

Vue.prototype.$http = axios;
Vue.use(util);
Vue.use(DatetimePicker)
Vue.use(VueSignaturePad);
Vue.prototype.$secureStorage = secureStorage;

Vue.mixin({
    methods: {
        getCompanyLogoURL: isFullURL => {
            let company_id = null;
            const searchObj = Vue.urlHelper.extractQuery();
            
            if (searchObj["company_id"]) {
                company_id = searchObj["company_id"];
            }

            let url = 'company/logo?logo_source=general&check_customization=false&domain=' + window.location.host;

            if (company_id) {
                url = url + "&company_id=" + company_id;
            }

            if (isFullURL) {
                url = process.env.VUE_APP_DASHBOARD_API + url ;
            }

            return url;
        }
    }
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')