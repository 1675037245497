<template>
  <v-container fluid id="nav">
    <div class="row pt-2 pb-2 align-center justify-center">
      <div v-if="isDesktop" class="col-2 lang">
        <language-switcher />
      </div>
      <div v-if="isDesktop" class="col-8 menu">
        <v-btn depressed plain text @click="goTo('Home')">
          <v-icon>mdi-magnify</v-icon>
          <span class="text-capitalize subtitle-1">
            {{ $t("FindVehicle") }}
          </span>
        </v-btn>
        <account-panel-btns :styles="{ margin: '0px 10px' }" />
      </div>
      <div
        class="logo col-9 col-md-2 pa-0"
      >
        <div @click="goTo('Home')" class="logo-img">
          <async-image
            :source-link="getCompanyLogoURL(false)"
            :isFromAssets="false"
            :image-attrs="{
              contain: true,
            }"
          ></async-image>
        </div>
      </div>
      <v-app-bar-nav-icon
        class="col-2"
        v-if="!isDesktop"
        @click.stop="drawer = !drawer"
      />
      <v-navigation-drawer
        class="mobile-navbar"
        v-if="!isDesktop"
        v-model="drawer"
        fixed
        temporary
        dark
      >
        <account-nav-bar v-if="isLoggedIn && !isDesktop" />
        <v-divider></v-divider>
        <v-list nav>
          <v-list-item @click="goTo('Home')">
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <v-btn depressed plain text >
                <span class="subtitle-2">
                  {{ $t("FindVehicle") }}
                </span>
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <account-panel-list />
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <language-switcher v-if="!isDesktop" />
      </v-navigation-drawer>
    </div>
  </v-container>
</template>

<script>
import Vue              from "vue";
import LanguageSwitcher from "./Util/LanguageSwitcher";
import AsyncImage       from "./Util/AsyncImage";
import AccountPanelBtns from "./Account/AccountPanelBtns";
import AccountPanelList from "./Account/AccountPanelList";
import AccountNavBar    from "./Account/NavBar";
import { mapGetters }   from "vuex";

export default {
  name: "NavBar",
  components: {
    LanguageSwitcher,
    AsyncImage,
    AccountPanelBtns,
    AccountPanelList,
    AccountNavBar,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isMobile: "isMobile",
      isTablet: "isTablet",
      isDesktop: "isDesktop",
      company: "company/getCompany"
    }),
  },
  methods: {
    goTo(routeName) {
        var searchObj = Vue.urlHelper.extractQuery();
        if (searchObj["company_id"]) {
          this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
        } else {
          this.$router.push({name: routeName});
        }
    }
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
.mobile-navbar {
  z-index: 100;
}
#nav {
  background-color: #FFFFFF;
  text-align: center;
  z-index: 999;
  width: 100%;
  height: 4.5rem;
  @media (max-width:767px) {
    height: 8rem;
  }
}

#nav a {
  text-decoration: none;
  text-indent: 1em;
}
.lang {
  display: flex;
  align-self: flex-start;
  justify-content: center;
}
.menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-img {
  max-width:100px;
  height:auto;
}
</style>
