import Vue          from 'vue';
import axios        from 'axios';
import store        from '../store';

async function show() {
    return await new Promise((resolve, reject) => {
        axios
            .get(`profile/show`)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function update(data = {}) {
    const formData = Vue.objectHelpers.formalize(data);

    return await new Promise((resolve, reject) => {
        axios
            .post(`profile/update`, formData)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function deleteSignature() {
    return await new Promise((resolve, reject) => {
        axios
            .post(`profile/delete-signature`)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function deleteDocument(id) {
    return await new Promise((resolve, reject) => {
        axios
            .delete(`profile/documents/${id}`)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function addDocument(data = {}) {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('label', data.label);

    return await new Promise((resolve, reject) => {
        axios
            .post(`profile/documents`, formData, 
            {
                onUploadProgress: function( progressEvent ) {
                    store.commit("profile/UPLOAD_PROGRESS", parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) ));
                }.bind(this),
            })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                store.commit("profile/RESET_UPLOAD_PROGRESS");
            });
    })
}

async function downloadDocument(id) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`profile/documents/${id}`, {
                responseType: 'blob',
            })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

export {
    show,
    update,
    deleteSignature,
    deleteDocument,
    addDocument,
    downloadDocument
}