export default {
    transmissions: [
        'Automatic',
        'Manual',
        'ElectronicContinuouslyVariable',
        'ContinuouslyVariableTransmission',
        'AutomatedManualTransmission',
        'MotorcycleShaftDrive',
        'MotorcycleChainDrive',
        'MotorcycleShaftDriveOffRoad',
        'MotorcycleCVTBeltDrive',
        'MotorcycleChainDriveOffRoad',
        'DualClutchTransmission',
    ],
    types: [
        "Convertible",
        "Coupe",
        "Crossover",
        "Hatchback",
        "Limousine",
        "Microcar",
        "Minivan",
        "PickupTruck",
        "Sedan",
        "SportsCar",
        "StationWagon",
        "SUV"
    ],
    classes: [
        { segment: 'A', description: 'Mini cars', example: 'Smart Fortwo, Renault Twingo' },
        { segment: 'B', description: 'Small cars', example: 'Volkswagen Polo, Mini' },
        { segment: 'C', description: 'Medium cars', example: 'Volkswagen Golf, BMW 1-Series' },
        { segment: 'D', description: 'Large cars', example: 'Mercedes C-Class, Audi A4' },
        { segment: 'E', description: 'Executive cars', example: 'Mercedes E-Class, BMW 5-Series' },
        { segment: 'F', description: 'Luxury cars', example: 'Mercedes S-Class, BMW 7-Series' },
        { segment: 'J', description: 'Sport utility vehicle/SUV', example: 'Ford Kuga, Volkswagen Tiguan' },
        { segment: 'M', description: 'Multiple purpose cars/MPV', example: 'Mercedes B-Class, Opel Zafira' },
        { segment: 'S', description: 'Sports cars', example: 'Audi TT, Porsche 991' },
    ],
}