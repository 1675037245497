const extractQuery = () => {

    var searchStr = '', searchObj = {};

    searchStr = location.search.substring(1);

    if (searchStr) {
        searchObj = JSON.parse('{"' + decodeURI(searchStr).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
    }

    return searchObj;
}

const convertObjectToQuery = (obj, prefix) => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

const setQuery = (obj) => {
    let searchStr = convertObjectToQuery(obj);
    return searchStr;
    // window.location.search = searchStr;
}

const routeHasQueryParams = (route) => {
    return !!Object.keys(route.query).length
}


export default {
    extractQuery,
    routeHasQueryParams,
    convertObjectToQuery,
    setQuery
}