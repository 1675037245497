import axios from 'axios';

async function login(credentials = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .post(`login`, credentials)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function register(data = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .post(`register`, data)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function forgotPassword(data = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .post(`forgot-password`, data)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function resetPassword(data = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .post(`reset-password`, data)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function changePassword(data = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .post(`change-password`, data)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function deleteAvatar() {
    return await new Promise((resolve, reject) => {
        axios
            .post(`avatar/delete`)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function updateAvatar(data = {}) {
    const formData = new FormData();
    formData.append('photo', data.photo);

    return await new Promise((resolve, reject) => {
        axios
            .post(`avatar/update`, formData)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function verifyEmail(url) {
    return await new Promise((resolve, reject) => {
        axios
            .get(url)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

export {
    login,
    register,
    forgotPassword,
    resetPassword,
    changePassword,
    deleteAvatar,
    updateAvatar,
    verifyEmail
}