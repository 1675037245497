import Vue            from 'vue'
import Vuex           from 'vuex'
import i18n           from '../i18n/i18n';

import cars           from './cars';
import auth           from './auth';
import currencies     from './currency';
import countries      from './countries';
import profile        from './profile';
import company        from './company';

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    isMobile: _ => {
      return window.innerWidth < 600;
    },
    isTablet: _ => {
      return window.innerWidth >= 600  && window.innerWidth < 960
    },
    isDesktop: _ => {
      return window.innerWidth > 960;
    },
    isEmbed: _ => {
      var searchObj = Vue.urlHelper.extractQuery();
      return searchObj['embed'] && searchObj['embed'] == 1;
    },
    isRtl: _ => {
      return i18n.locale == "ar";
    },
    locale: _ => {
      return i18n.locale
    },
    getStatuses: state => {
      return state.statuses;
    },
    getReceiptTypes: state => {
      return state.receiptTypes
    },
    getPaymentTypes: state => {
      return state.paymentTypes
    },
  },
  state: {
    statuses: [
      {text: i18n.t('Done'), value: 'done', color: 'primary'},
      {text: i18n.t('Pending'), value: 'pending', color: 'orange'},
      {text: i18n.t('Active'), value: 'active', color: 'green'},
      {text: i18n.t('Canceled'), value: 'canceled', color: 'red'},
    ],
    receiptTypes: [
      {text: i18n.t('PaymentReceipt'), value: 1},
      {text: i18n.t('CatchReceipt'), value: 0}
    ],
    paymentTypes: [
      {text: i18n.t('Cash'), value: 'cash'},
      {text: i18n.t('CreditCard'), value: 'credit-card'},
      {text: i18n.t('BankTransfer'), value: 'transfer'}
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    cars,
    auth,
    currencies,
    countries,
    profile,
    company,
  }
})
