export default {
    Homepage: "الرئيسية",
    Vehicle: "المركبة | المركبات",
    FindTheSuitableCarAndGetOnRoad: "ابحث عن المركبة المناسبة لك ثم انطلق في الطريق ",
    ChooseReservationStartandEndDates: "اختر تاريخ بدء الحجز وتاريخ انتهائه",
    Search: "ابحث",
    FindVehicle: "ابحث عن مركبة",
    ReceivingDate: "تاريخ الاستلام",
    DeliveringDate: "تاريخ التسليم",
    VehicleRentalServices: "خدمات تأجير المركبات",
    LoadMore: "عرض المزيد",
    ReserveNow: "احجز الآن",
    Filter: "البحث",
    Apply: "تأكيد",
    ReservationDate: "تاريخ الحجز",
    NumberOfSeats: "عدد المقاعد",
    NumberOfPackages: "عدد الأمتعة",
    VehicleType: "نوع المركبة",
    VehicleTransmission: "ناقل الحركة",
    VehicleBrand: "ماركة المركبة",
    NoVehiclesFound: "لم يتم العثور على مركبات. اختر تصفية البحث للبدء.",
    ShowingVehiclesBetween: "عرض المركبات المتاحة بين",
    And: "و",
    PerDay: "باليوم",
    Price: "السعر",
    ErrorFetchingData: "حصل خطأ في إيجاد البيانات. الرجاء المحاولة لاحقاً أو قم بالتبليغ عن هذا الخطأ",
    SearchAndRentAVehicle: "ابحث عن مركبة وقم بالحجز",
    ReservationConfirmation: 'تأكيد الحجز',
    ViewReservationInformationAndConfirm: "الرجاء مراجعة البيانات الخاصة بالحجز ومن ثم التأكيد. و سوف نقوم بالتواصل معك خلال دقائق",
    NoMoreVehicles: "لا يوجد مركبات أخرى",
    NumberOfDays: "عدد الأيام",
    PricesMightBeSubjectToTaxes: "الأسعار قد تكون خاضعة لضريبة",
    WeJustNeedToKnowWhoYouAre: "نحن فقط بحاجة لمعرفة من أنت!",
    FieldRequired: 'يجب تعبئة الحقل',
    FieldMustBeEmail: 'يجب إدخال بريد الكتروني صحيح',
    FullName: 'الاسم الكامل',
    FirstName: 'الاسم الأول',
    Phone: "رقم الهاتف",
    Email: "البريد الالكتروني",
    MissingReservationInformation: "بعض معلومات الحجز مفقودة ، يرجى إعادة إرسال الحجز",
    ReservationInformation: "معلومات الحجز",
    YourInformation: "معلوماتك الشخصية",
    VehicleInformation: "معلومات المركبة",
    Confirm: "تأكيد",
    Cancel: "إلغاء الأمر",
    ConfirmReservation: "تأكيد الحجز",
    ReserveVehicle: "احجز مركبة",
    NoDatesSelected: "لم يتم تحديد تاريخ الاستلام والتسليم",
    ThankYouForReservation: "تم إرسال طلب الحجز المبدئي, سيتم التواصل معك خلال بضعة دقائق لتأكيد الحجز! شكراً لك.",
    DeliverToDifferentLocation: "العودة إلى موقع مختلف",
    ReceivingLocation: "موقع الاستلام",
    DeliveringLocation: "موقع التسليم",
    SearchLocation: "ابحث عن المدينة",
    NoCitiesFound: "لم يتم العثور على مدن",
    Notes: "ملاحظات",
    WhenDoYouWantToReserveTheVehicle: "متى ومن أين تريد حجز المركبة؟",
    ReceivingAtDate: "الاستلام في تاريخ",
    InLocation: "في منطقة",
    DeliveringAtDate: "التسليم في تاريخ",
    Automatic: 'أوتوماتيكي',
    Manual: 'يدوي',
    ElectronicContinuouslyVariable: 'المتغير الإلكتروني المستمر (e-CVT)',
    ContinuouslyVariableTransmission: 'ناقل الحركة المتغير باستمرار (CVT)',
    AutomatedManualTransmission: 'ناقل الحركة اليدوي الآلي (AMT)',
    MotorcycleChainDrive: 'دراجة نارية - سلسلة القيادة',
    MotorcycleCVTBeltDrive: 'دراجة نارية - حزام سير CVT',
    MotorcycleShaftDrive: 'دراجة نارية - محرك شافت',
    MotorcycleShaftDriveOffRoad: 'دراجة نارية - رمح القيادة على الطرق الوعرة',
    DualClutchTransmission: 'ناقل حركة ثنائي القابض (DCT)',
    MotorcycleChainDriveOffRoad: "دراجة نارية - سلسلة القيادة على الطرق الوعرة",
    Convertible: "مكشوفة",
    Coupe: "كوبيه",
    Crossover: "Crossover",
    Hatchback: "هاتشباك",
    Limousine: "ليموزين",
    MicroCar: "سيارة مايكرو",
    Minivan: "فان صغير",
    PickupTruck: "شاحنة صغيرة",
    Sedan: "سيدان",
    SportsCar: "سيارة رياضية",
    StationWagon: "ستيشن واجون",
    SUV: "SUV",
    Print: "طباعة",
    Note: 'ملاحظة',
    ThisReservationRequestIsNotConfirmedYet: 'لم يتم تأكيد طلب الحجز المرفق بعد',
    EnterThreeCharactersToSearch: 'قم بكتابة 3 حروف على الأقل للبحث',
    LoginFormHeader: 'تسجيل الدخول',
    Enter: "دخول",
    RegisterHere: "ليس لديك حساب؟ قم بالتسجيل هنا",
    Account: 'حسابي الشخصي',
    RegisterFormHeader: 'تسجيل حساب جديد',
    Signup: 'تسجيل',
    LoginHere: "لديك حساب بالفعل؟ قم بتسجيل الدخول هنا",
    Username: "اسم المستخدم",
    Password: "كلمة المرور",
    PasswordConfirmation: "تأكيد كلمة المرور",
    PasswordDontMatch: "كلمة المرور غير مطابقة",
    AtLeast8CharactersLong: "يجب كتابة 8 حروف على الأقل",
    Welcome: 'مرحباً',
    Guest: 'زائر',
    Reservation: 'الحجز | الحجوزات',
    Receipt: 'السند | السندات',
    Logout: "تسجيل الخروج",
    PersonalInformation: "المعلومات الشخصية",
    Residency: "الإقامة",
    DrivingLicense: "رخصة القيادة",
    CreditCard: "بطاقة الائتمان",
    Signature: "التوقيع",
    InvalidEmailOrPassword: "الرجاء التأكد من صحة البيانات",
    ForgotPassword: "نسيت كلمة المرور؟",
    ResetPassword: "إعادة كلمة المرور",
    Cancel: "إلغاء الأمر",
    Submit: "إرسال",
    AccountSecurity: "أمن الحساب",
    ReservationNumber: "رقم الحجز",
    ClickToView: "إضغط للمشاهدة",
    ReservationAddress: "عناوين الاستلام والتسليم",
    Receiving: "الاستلام",
    Delivering: "التسليم",
    Done: "منتهي",
    Pending: "قيد الانتظار",
    Active: "فعّال",
    Canceled: "ملغى",
    Status: "الحالة",
    TotalCost: "الكلفة الإجمالية",
    Details: "التفاصيل",
    CostDetails: 'تفاصيل التكلفة',
    Close: "إغلاق",
    Discount: "الحسم",
    TaxRate: "نسبة الضريبة",
    RentalCost: "كلفة الحجز",
    Tax: "الضريبة",
    NoData: "لا يوجد بيانات",
    LoadingData: "يتم تحميل البيانات...",
    All: "الكل",
    Of: "من",
    RowsPerPage: "عدد الأسطر في الصفحة",
    ReceiptID: "رقم السند",
    Date: "التاريخ",
    PaymentType: "نوع الدفعة",
    Amount: "القيمة",
    Cash: 'نقداً',
    BankTransfer: 'حوالة بنكية',
    Type: 'النوع',
    PaymentReceipt: 'سند دفع',
    CatchReceipt: 'سندق قبض',
    NewPassword: "كلمة المرور الجديدة",
    OldPasswordIncorrect: "كلمة المرور الحالية خاطئة",
    PasswordChanged: "تم تغيير كلمة المرور",
    PasswordResetLinkSent: "تم إرسال رابط تغيير كلمة المرور للبريد الاكتروني الخاص بك",
    InvalidResetLink: "رابط تغيير كلمة المرور غير صحيح.",
    Address: "العنوان",
    Female: "أنثى",
    Male: "ذكر",
    Gender: "الجنس",
    Nationality: "الجنسية",
    BirthDate: "تاريخ الميلاد",
    BirthPlace: "مكان الولادة",
    AdditionalPhone: "رقم هاتف إضافي",
    SaveEdits: "حفظ التعديلات",
    IsForeignResident: "مقيم أجبني؟",
    Country: "البلد",
    State: "المحافظة / الولاية",
    City: "المدينة",
    ResidenceNumber: "رقم الهوية / الإقامة",
    PassportNumber: "رقم جواز السفر",
    DrivingLicenseNumber: "رقم رخصة القيادة",
    DrivingLicenseType: "نوع رخصة القيادة",
    EnglishName: "الاسم باللغة الانكليزية",
    CreditCardNumber: "رقم بطاقة الائتمان",
    ExpiryDate: "تاريخ انتهاء الصلاحية",
    SecurityCode: "الرقم السرّي",
    ProfileUpdated: "تم تعديل الملف الشخصي",
    ErrorProfileUpdate: "حصل خطأ في تحديث البيانات, الرجاء المحاولة لاحقاً",
    SomeFieldsAreInvalid: "الرجاء التأكد من قيم الحقول, بعض الحقول خاطئة",
    PleaseChooseReservationDateAndLocation: 'الرجاء اختيار توقيت ومكان الحجز',
    InvalidToken: "الرجاء طلب رابط إعادة ضبط كلمة المرور من جديد.",
    Document: "المستند | المستندات",
    Add: "إضافة",
    AddDocument: "إضافة مستند جديد",
    Name: "الاسم",
    File: "الملف",
    DocumentDeletedSuccessfully: "تم حذف  المستند بنجاح",
    DrawNewSignature: "ارسم توقيع جديد",
    CurrentSignature: "التوقيع الحالي",
    PleaseCompleteProfileInformation: "الرجاء إكمال معلوماتك الشخصية (بيانات رخصة القيادة و رقم الهاتف)",
    OrSimilar: "أو ما شابه ذلك",
    DailyPrice: "السعر اليومي",
    TotalPrice: "السعر الإجمالي",
    DayOrDays: "يوم",
    OrderBy: "تصنيف حسب",
    Desc: "تنازلي",
    Asc: "تصاعدي",
    Class: "الفئة",
    Direction: "الإتجاه",
    Sort: "ترتيب",
    Company: "الشركة | الشركات",
    LastName: 'الاسم الأخير',
    ClickToReview:"تقييم الحجز",
    NoNote:"لا يوجد اي ملاحظات خاصة بهذا الحجز",
    review:"تقييم",
    CannotReviewNow: "لا يمكنك القييم حاليا",
    UpdateReview:"تعديل القييم",
    sortBy :"الترتيب بحسب",
    SignatureDeleted :"تم حذف التوقيع",
    Delete : "حذف التوقيع",
    Undo:"تراجع",
    ApplyCoupon: "تطبيق كود الشراكة أو الحسم",
    "Enter Coupon Code": "أدخل كوبون الشراكة او الحسم",
    "Enter coupon code first": "أدخل كود الشراكة او الحسم أولاً",
    "Coupon not found": "كود الشراكة او الحسم غير موجود",
    "Coupon applied successfully": "تم تطبيق كود الشراكة او الحسم بنجاح",
    Final: "النهائي"
}