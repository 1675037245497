<template>
  <div>
    <v-divider color="#d3d3d3"></v-divider>
    <v-btn
      x-small
      v-show="currentLocale !== 'ar'"
      @click="setLocale('ar')"
      depressed
      text
    >
      العربية
    </v-btn>
    <v-btn
      x-small
      v-show="currentLocale !== 'en'"
      @click="setLocale('en')"
      depressed
      text
    >
      English
    </v-btn>
    <v-btn
      x-small
      v-show="currentLocale !== 'tr'"
      @click="setLocale('tr')"
      depressed
      text
    >
      Türkçe
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      currentLocale: this.$secureStorage.getItem("locale") || "ar"
    }
  },
  methods: {
    setLocale(locale) {
      locale == "ar" ? this.$vuetify.rtl = true : this.$vuetify.rtl = false;
      this.$secureStorage.setItem("locale", locale);
      this.$i18n.locale = this.currentLocale = locale;
    },
  },
};
</script>

