<template>
  <v-btn fab v-bind="btnAttrs" @click="$emit('clicked')">
    <v-icon>{{ btnAttrs.iconClass }}</v-icon>
  </v-btn>
</template>

<script>
export default {
    name: "AccountFloatingButton",
    props: {
        btnAttrs: {
            type: Object,
            default: {}
        }
    }
}
</script>