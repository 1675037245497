import Vue              from 'vue'
import VueRouter        from 'vue-router'
import routes           from './routes';
import store            from '../store';
import secureStorage    from "../plugins/secureStorage";

//avoiding redundant navigation
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    
    // TODO global handling four routes with preserved query params
    var hasParams   = Vue.urlHelper.routeHasQueryParams(from) && to.meta.preserveQueryParams;
    var params      = {...to.query, ...from.query}; 

    // var toWithQuery = { ...to };
    // if (hasParams) {
        // toWithQuery = { ...to, query: params };
    // }

    // preventing unauthorized access
    if (to.matched.some(record => record.meta.requiresAuth)) {

        // preventing access
        if (!store.getters['auth/isLoggedIn']) {
            next({name: 'LoginPage'})
        } else {
            next();
        }

    } else {
        next();
    }
})

export default router