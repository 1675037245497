export default {
    Homepage: "Homepage",
    Vehicle: "Vehicle | Vehicles",
    FindTheSuitableCarAndGetOnRoad: "Find The Suitable Vehicle And Get On Road",
    ChooseReservationStartandEndDates: "Choose Reservation Start and End Dates",
    Search: "Search",
    FindVehicle: "Find Vehicle",
    ReceivingDate: "Pick-up Date",
    DeliveringDate: "Delivering Date",
    VehicleRentalServices: "Vehicle Rental Services",
    LoadMore: "Load More",
    ReserveNow: "Reserve Now",
    Filter: "Filter",
    Apply: "Apply",
    ReservationDate: "Reservation Date",
    NumberOfSeats: "Number Of Seats",
    NumberOfPackages: "Number Of Packages",
    VehicleType: "Vehicle Type",
    VehicleTransmission: "Vehicle Transmission",
    VehicleBrand: "Vehicle Brand",
    NoVehiclesFound: "No vehicles found. Choose search filtering to start.",
    ShowingVehiclesBetween: "Showing available vehicles between",
    And: "And",
    PerDay: "Per Day",
    Price: "Price",
    ErrorFetchingData: "Error fetching data. Please try again later or report this error",
    SearchAndRentAVehicle: "Search and Rent a Vehicle",
    ReservationConfirmation: "Reservation Confirmation",
    ViewReservationInformationAndConfirm: "Please view the following reservation information and confirm. Then we will contact you in few minutes.",
    NoMoreVehicles: "No more vehicles",
    NumberOfDays: "Number of Days",
    PricesMightBeSubjectToTaxes: "Prices might be subject to taxes",
    WeJustNeedToKnowWhoYouAre: "We just need to know who you are!",
    FieldRequired: 'This field is required',
    FieldMustBeEmail: 'Please enter valid email address',
    FullName: "Full Name",
    FirstName: 'First Name',
    Phone: "Phone",
    Email: "Email",
    MissingReservationInformation: "Some reservation information is missing, please re submit the reservation",
    ReservationInformation: "Reservation Information",
    YourInformation: "Your Information",
    VehicleInformation: "Vehicle Information",
    Confirm: "Confirm",
    Cancel: "Cancel",
    ConfirmReservation: "Confirm Reservation",
    ReserveVehicle: "Reserve Vehicle",
    NoDatesSelected: "No date has been set for pick-up and delivery",
    ThankYouForReservation: "Your initial reservation request has been sent, we will contact you within a few minutes to confirm your reservation! Thank you.",
    DeliverToDifferentLocation: "Return to different location",
    ReceivingLocation: "Pickup Location",
    DeliveringLocation: "Delivering Location",
    SearchLocation: "Search city",
    NoCitiesFound: "No cities found.",
    Notes: "Notes",
    WhenDoYouWantToReserveTheVehicle: "When and where do you want to reserve the vehicle?",
    ReceivingAtDate: "Pickup on",
    InLocation: "In",
    DeliveringAtDate: "Delivery on",
    AutomatedManualTransmission: 'Automated Manual Transmission (AMT)',
    Automatic: 'Automatic',
    Manual: 'Manual / Standard',
    ElectronicContinuouslyVariable: 'Electronic Continuously Variable (e-CVT)',
    ContinuouslyVariableTransmission: 'Continuously Variable Transmission (CVT)',
    MotorcycleChainDrive: 'Motorcycle - Chain Drive',
    MotorcycleCVTBeltDrive: 'Motorcycle - CVT Belt Drive',
    MotorcycleShaftDrive: 'Motorcycle - Shaft Drive',
    MotorcycleShaftDriveOffRoad: 'Motorcycle - Shaft Drive Off-Road',
    DualClutchTransmission: 'Dual-clutch Transmission (DCT)',
    MotorcycleChainDriveOffRoad: "Motorcycle - Chain Drive Off Road",
    Convertible: "Convertible",
    Coupe: "Coupe",
    Crossover: "Crossover",
    Hatchback: "Hatchback",
    Limousine: "Limousine",
    MicroCar: "Micro Car",
    Minivan: "Minivan",
    PickupTruck: "Pickup Truck",
    Sedan: "Sedan",
    SportsCar: "Sports Car",
    StationWagon: "Station Wagon",
    SUV: "SUV",
    Print: "Print",
    Note: 'Note',
    ThisReservationRequestIsNotConfirmedYet: 'This reservation request is not confirmed yet.',
    EnterThreeCharactersToSearch: 'Enter 3 characters to start searching..',
    LoginFormHeader: 'Login to your account',
    Enter: "Enter",
    RegisterHere: "Don't have account? Register here.",
    Account: 'My Account',
    RegisterFormHeader: 'Register new account',
    Signup: 'Signup',
    LoginHere: "Already have account? Login here.",
    Username: "Username",
    Password: "Password",
    PasswordConfirmation: "Password Confirmation",
    PasswordDontMatch: "Password don't match",
    AtLeast8CharactersLong: "Text must be at least 8 characters.",
    Welcome: 'Welcome',
    Guest: 'Guest',
    Reservation: 'Reservation | Reservations',
    Receipt: 'Receipt | Receipts',
    Logout: "Logout",
    PersonalInformation: "Personal Information",
    Residency: "Residency",
    DrivingLicense: "Driving License",
    CreditCard: "Credit Card",
    Signature: "Signature",
    InvalidEmailOrPassword: "Invalid email/password combination",
    ForgotPassword: "Forgot Password?",
    ResetPassword: "Reset Password",
    Cancel: "Cancel",
    Submit: "Submit",
    AccountSecurity: "Account Security",
    ReservationNumber: "Reservation Number",
    ClickToView: "Click to view",
    ReservationAddress: "Receiving & Delivering Addresses",
    Receiving: "Receiving",
    Delivering: "Delivering",
    Done: "Done",
    Pending: "Pending",
    Active: "Active",
    Canceled: "Canceled",
    Status: "Status",
    TotalCost: "Total Cost",
    Details: "Details",
    CostDetails: 'Cost Details',
    Close: "Close",
    Discount: "Discount",
    TaxRate: "Tax Rate",
    RentalCost: "Rent Cost",
    Tax: "Tax",
    NoData: "No data to display",
    LoadingData: "Loading data...",
    All: "All",
    Of: "Of",
    RowsPerPage: "Rows per page",
    ReceiptID: "Receipt ID",
    Date: "Date",
    PaymentType: "Payment Type",
    Amount: "Amount",
    Cash: 'Cash',
    BankTransfer: 'Bank transfer',
    Type: 'Type',
    PaymentReceipt: 'Payment Receipt',
    CatchReceipt: 'Catch Receipt',
    NewPassword: "New password",
    OldPasswordIncorrect: "Current password is invalid",
    PasswordChanged: "Password changed successfully",
    PasswordResetLinkSent: "Password reset link has been sent to your email",
    InvalidResetLink: "Invalid password reset link",
    Address: "Address",
    Female: "Female",
    Male: "Male",
    Gender: "Gender",
    Nationality: "Nationality",
    BirthDate: "Birth Date",
    BirthPlace: "Birth Place",
    AdditionalPhone: "Additional Phone",
    SaveEdit: "Save Edits",
    IsForeignResident: "Foreign Resident?",
    Country: "Country",
    State: "State / Governorate",
    City: "City",
    ResidenceNumber: "Residence Number.",
    PassportNumber: "Passport Number",
    DrivingLicenseNumber: "Driving License Number",
    DrivingLicenseType: "Driving License Type",
    EnglishName: "English Name",
    CreditCardNumber: "Credit Card Number",
    ExpiryDate: "Expiry Date",
    SecurityCode: "Security Code",
    ProfileUpdated: "Profile updated successfully.",
    ErrorProfileUpdate: "Error updating information, please try again later",
    SomeFieldsAreInvalid: "Please check input data. Some input data are invalid",
    PleaseChooseReservationDateAndLocation: 'Please choose reservation date and location',
    InvalidToken: "Please request a new password reset link.",
    Document: "Document | Documents",
    Add: "Add",
    AddDocument: "Add new document",
    Name: "Name",
    File: "File",
    DocumentDeletedSuccessfully: "Document deleted successfully",
    DrawNewSignature: "Draw new signature",
    CurrentSignature: "Current signature",
    PleaseCompleteProfileInformation: "Please complete your personal information (driving license data and phone number)",
    OrSimilar: "Or Similar",
    DailyPrice: "Price per day",
    TotalPrice: "Total Price",
    DayOrDays: "Day(s)",
    OrderBy: "Order by",
    Desc: "Descendence",
    Asc: "Ascendance",
    Class: "Class",
    Direction: "Direction",
    Sort: "Sort",
    Company: "Company | Companies",
    LastName: 'Last Name',
    ClickToReview:"Reservation evaluation",
    NoNote:"There are no notes for the account.",
    review:"Rating",
    CannotReviewNow:"you cannot rate now",
    UpdateReview : "Rating update",
    SaveEdits:"Save Edits",
    sortBy:"sort by",
    SignatureDeleted:"signature deleted",
    Delete:"Delete",
    Undo:"Undo",
    ApplyCoupon: "Apply affiliate or discount coupon",
    "Enter Coupon Code": "Enter affiliate or discount coupon Code",
    "Enter coupon code first": "Enter affiliate or discount coupon code first",
    "Coupon not found": "Coupon not found",
    "Coupon applied successfully": "Coupon applied successfully!",
    Final: "Final"
}