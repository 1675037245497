<template>
  <v-container fluid fill-height>
    <v-row>
      <v-col cols="12" style="padding: 0px">
        <div
          v-if="updatingProfile || deletingDocument || downloadingDocument"
          class="loader"
        >
          <devbey-logo-loader></devbey-logo-loader>
        </div>
        <v-toolbar flat>
          <v-toolbar-title> {{ $t("Account") }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="updateProfile" :loading="updatingProfile">
            <v-icon>mdi-check</v-icon>
            {{ $t("SaveEdits") }}
          </v-btn>
          <slot name="header-actions" />
        </v-toolbar>
        <v-form v-model="isValid" ref="profile-form-ref">
          <v-tabs
            v-if="dataReady"
            grow
            centered
            show-arrows
            background-color="#EEF0EB"            
          >
            <v-tabs-slider ></v-tabs-slider>
            <v-tab>
              <v-icon>mdi-account</v-icon>
              {{ $t("PersonalInformation") }}
            </v-tab>
            <v-tab-item class="profile-form pa-5" eager :key="1">
              <v-row>
                <v-col cols="12" sm="3" class="text-center">
                  <Avatar :size="175" controls />
                </v-col>
                <v-col cols="12" sm="9">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="customer.name"
                        :label="$t('FirstName')"
                        outlined
                        :rules="[filterRules.required]"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="customer.surname"
                        :label="$t('LastName')"
                        outlined
                        :rules="[filterRules.required]"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <keep-alive>
                        <country-code-phone-input
                          :field-attrs="{
                            appendIcon: 'mdi-phone',
                            label: $t('Phone'),
                            outlined: true,
                            rules: [filterRules.required],
                            value: customer.phone,
                          }"
                          :autocomplete-attrs="{
                            outlined: true,
                            rules: [filterRules.required],
                            value: customer.phone_country_code,
                          }"
                          @phone-changed="
                            (phone) => (
                              (customer.phone = phone)
                            )
                          "
                          @code-phone-changed="(code)=>{customer.phone_country_code = code}"
                        />
                      </keep-alive>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="customer.email"
                        :label="$t('Email')"
                        color="dark"
                        outlined
                        readonly
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model="customer.address"
                        :label="$t('Address')"
                        color="dark"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-radio-group
                        row
                        :label="$t('Gender')"
                        v-model="customer.gender"
                      >
                        <v-radio :label="$t('Female')" value="female" />
                        <v-radio :label="$t('Male')" value="male" />
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="customer.nationality"
                    :items="countries"
                    cache-items
                    item-text="name"
                    item-value="name"
                    color="dark"
                    :label="$t('Nationality')"
                    outlined
                    :menu-props="{ offsetY: true }"
                    :no-data-text="$t('NoData')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu :close-on-content-click="false" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        color="dark"
                        outlined
                        v-model="customer.birth_date"
                        :label="$t('BirthDate')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="customer.birth_date"
                      :max="new Date().toISOString()"
                      :locale="locale"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="customer.birth_place"
                    :label="$t('BirthPlace')"
                    color="dark"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="customer.additional_phone"
                    :label="$t('AdditionalPhone')"
                    color="dark"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab>
              <v-icon>mdi-map-marker</v-icon>
              {{ $t("Residency") }}
            </v-tab>
            <v-tab-item class="profile-form pa-5" eager :key="2">
              <v-row>
                <v-col v-show="false" cols="12" md="6" lg="3">
                  <v-checkbox
                    v-model="customer.is_foreign_resident"
                    :label="$t('IsForeignResident')"
                    color="dark"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-autocomplete
                    v-model="customer.country_id"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    color="dark"
                    :label="$t('Country')"
                    outlined
                    :menu-props="{ offsetY: true }"
                    :no-data-text="$t('NoData')"
                    @change="onResidentCountryChange"
                  >
                </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-autocomplete
                    v-model="customer.state_id"
                    item-text="name"
                    item-value="id"
                    :items="states"
                    :label="$t('State')"
                    color="dark"
                    outlined
                    :menu-props="{ offsetY: true }"
                    :no-data-text="$t('NoData')"
                    @change="onResidentStateChange"
                    :loading="true"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-autocomplete
                    v-model="customer.city_id"
                    item-text="name"
                    item-value="id"
                    :items="cities"
                    :label="$t('City')"
                    color="dark"
                    outlined
                    :menu-props="{ offsetY: true }"
                    :no-data-text="$t('NoData')"
                    :loading="true"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="3">
                  <v-text-field
                    v-model="customer.residence_number"
                    :label="$t('ResidenceNumber')"
                    color="dark"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <v-menu :close-on-content-click="false" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        color="dark"
                        outlined
                        v-model="customer.residence_expiry"
                        :label="$t('ExpiryDate')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="customer.residence_expiry"
                      :locale="locale"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <v-text-field
                    v-model="customer.passport_number"
                    :label="$t('PassportNumber')"
                    color="dark"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <v-menu :close-on-content-click="false" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        color="dark"
                        outlined
                        v-model="customer.passport_expiry"
                        :label="$t('ExpiryDate')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="customer.passport_expiry"
                      :locale="locale"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab>
              <v-icon>mdi-license</v-icon>
              {{ $t("DrivingLicense") }}
            </v-tab>
            <v-tab-item class="profile-form pa-5" eager :key="3">
              <v-row>
                <v-col cols="12" md="6" lg="3">
                  <v-text-field
                    v-model="customer.license_number"
                    :label="$t('DrivingLicenseNumber')"
                    color="dark"
                    outlined
                    :rules="[filterRules.required]"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <v-text-field
                    v-model="customer.license_type"
                    :label="$t('DrivingLicenseType')"
                    color="dark"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <v-menu :close-on-content-click="false" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        color="dark"
                        outlined
                        v-model="customer.license_expiry"
                        :label="$t('ExpiryDate')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="customer.license_expiry"
                      :locale="locale"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <v-autocomplete
                    v-model="customer.license_source_id"
                    cache-items
                    item-text="name"
                    item-value="id"
                    :items="countries"
                    :label="$t('Country')"
                    color="dark"
                    outlined
                    :menu-props="{ offsetY: true }"
                    :no-data-text="$t('NoData')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab>
              <v-icon>mdi-credit-card</v-icon>
              {{ $t("CreditCard") }}
            </v-tab>
            <v-tab-item class="profile-form pa-5" eager :key="5">
              <v-row>
                <v-col cols="12" md="6" lg="3">
                  <v-text-field
                    v-model="customer.credit_card.english_name"
                    :label="$t('EnglishName')"
                    color="dark"
                    outlined
                    :rules="[filterRules.englishText]"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <v-text-field
                    v-model="customer.credit_card.number"
                    :label="$t('CreditCardNumber')"
                    color="dark"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <v-menu :close-on-content-click="false" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        color="dark"
                        outlined
                        v-model="customer.credit_card.expiry_date"
                        :label="$t('ExpiryDate')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="customer.credit_card.expiry_date"
                      :locale="locale"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <v-text-field
                    v-model="customer.credit_card.security_code"
                    :label="$t('SecurityCode')"
                    color="dark"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab>
              <v-icon>mdi-folder</v-icon>
              {{ $tc("Document", 2) }}
            </v-tab>
            <v-tab-item class="profile-form" eager :key="6">
              <v-toolbar dense flat color="#ebebeb">
                <v-spacer></v-spacer>

                <v-dialog max-width="600">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn icon :title="$t('Add')" v-on="on" v-bind="attrs">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <add-document-form
                      v-bind="documentFormDialogProps"
                      @successfull-upload="dialog.value = false"
                    >
                      <template v-slot:card-actions>
                        <v-btn
                          color="darken-2 elevation-0"
                          dark
                          @click="dialog.value = false"
                        >
                          {{ $t("Close") }}
                          <v-icon small class="ma-1">mdi-cancel</v-icon>
                        </v-btn>
                      </template>
                    </add-document-form>
                  </template>
                </v-dialog>
              </v-toolbar>

              <v-row
                v-for="item in profile.documents"
                :key="item.id"
                no-gutters
              >
                <v-col cols="12" class="text-h6 pa-4 pb-0">
                  <v-card flat>
                    <v-card-text class="ma-0 pa-0">
                      <p class="ma-0 black--text font-weight-bold">
                        <v-icon>mdi-file</v-icon>
                        {{ item.label }}
                      </p>
                      <p class="ma-0">
                        {{ item.name }}
                      </p>
                      <p class="text-caption ma-0">
                        {{ new Date(item.date).toLocaleDateString() }}
                      </p>
                    </v-card-text>
                    <v-card-actions class="ma-0 pa-0">
                      <v-spacer></v-spacer>
                      <v-btn text small @click="downloadDocument(item)">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("Download") }}
                      </v-btn>
                      <v-btn
                        text
                        small
                        @click="deleteDocument(item.id)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                        {{ $t("Delete") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab>
              <v-icon>mdi-draw</v-icon>
              {{ $t("Signature") }}
            </v-tab>
            <v-tab-item class="profile-form pa-5" eager :key="7">
              <v-row justify="center">
                <v-col cols="12" sm="4">
                  <h3>
                    {{ $t("DrawNewSignature") }}
                    <v-btn
                      small
                      color="primary"
                      depressed
                      @click="undoSignature"
                    >
                      {{ $t("Undo") }}
                    </v-btn>
                  </h3>
                  <VueSignaturePad
                    width="350px"
                    height="250px"
                    ref="signaturePad"
                    :customStyle="{
                      background: 'rgba(0,0,0,.04)',
                    }"
                    :options="{
                      onEnd,
                      onBegin,
                      minWidth: 0.5,
                      maxWidth: 1.5,
                      throttle: 16,
                      minDistance: 5,
                      backgroundColor: 'rgba(0,0,0,0)',
                      penColor: '#006B88',
                      velocityFilterWeight: 0.7,
                    }"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <h3>
                    {{ $t("CurrentSignature") }}
                    <v-btn
                    v-if="customer.signature"
                      small
                      dark
                      depressed
                      @click="deleteSignature"
                    >
                      {{ $t("Delete") }}
                    </v-btn>
                  </h3>
                  <div
                    class="current-signature-wrapper"
                    :key="signatureCompKey"
                  >
                    <async-image
                      v-if="customer.signature"
                      :sourceLink="signatureImageLink"
                    ></async-image>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue                  from "vue";
import AccountBanner        from "../../../../components/Account/AccountBanner";
import AsyncImage           from "../../../../components/Util/AsyncImage";
import Avatar               from "../../../../components/Form/Avatar";
import { mapGetters }       from "vuex";
import AddDocumentForm      from "./AddDocument";
import DevbeyLogoLoader     from "../../../../components/Util/DevbeyLogoLoader";

export default {
  components: {
    AccountBanner,
    AsyncImage,
    Avatar,
    DevbeyLogoLoader,
    CountryCodePhoneInput: () =>
      import("../../../../components/Form/CountryCodePhoneInput"),
    AddDocumentForm,
  },
  data() {
    return {
      customer: {
        name: null,
        surname: null,
        email: null,
        phone: null,
        phone_country_code: null,
        address: null,
        additional_phone: null,
        birth_date: null,
        birth_place: null,
        gender: null,
        nationality: null,
        is_foreign_resident: true,
        country_id: null,
        state_id: null,
        city_id: null,
        residence_number: null,
        residence_expiry: null,
        passport_number: null,
        passport_expiry: null,
        license_number: null,
        license_source_id: null,
        license_type: null,
        license_expiry: null,
        signature: null,
        credit_card: {
          english_name: null,
          number: null,
          expiry_date: null,
          security_code: null,
        },
      },
      updatingProfile: false,
      deletingDocument: false,
      downloadingDocument: false,
      dataReady: false,
      phoneCountry: null,
      isValid: false,
      documentDialog: false,
      documentFormDialogProps: {
        enableSubmit: true,
        cardToolbarProps: {
          color: "darken-2 text-h6",
          dark: true,
        },
        cardActionsProps: {
          hasSpacer: true,
        },
      },
      signatureCompKey: 0,
      signatureImageLink: null,
    };
  },
  computed: {
    filterRules() {
      let rules = Vue.formHelper.filterRules();
      return {
        ...rules,
      };
    },
    ...mapGetters({
      locale:       "locale",
      countries:    "countries/getCountries",
      states:       "countries/getStates",
      cities:       "countries/getCities",
      profile:      "profile/getProfile",
    }),
  },
  methods: {
    updateProfile() {
      let validation = this.$refs["profile-form-ref"].validate();

      if (!this.isValid && !validation) {
        let showDefaultMessage = true;
        let customMessage = '';

        this.$refs["profile-form-ref"].inputs.forEach(function(input){
          if (input.hasError && input.messagesToDisplay.length > 0) {
            customMessage = input.label + ': ' + input.messagesToDisplay;
            showDefaultMessage = false;
            return;
          }
        });
        
        if (showDefaultMessage) {
          this.$root.$emit("notify-error", this.$t("SomeFieldsAreInvalid"));
        }
        else {
          this.$root.$emit("notify-error", customMessage);
        }

        return;
      }

      this.updatingProfile = true;

      const data = {
        ...this.customer,
        signature:
          this.customer.signature == "null" ? null : this.customer.signature,
      };

      this.$store
        .dispatch("profile/update", data)
        .then((resp) => {
          this.customer = {
            ...resp.data,
            credit_card: {
              english_name: this.profile.credit_card
                ? this.profile.credit_card.english_name
                : null,
              number: this.profile.credit_card
                ? this.profile.credit_card.number
                : null,
              security_code: this.profile.credit_card
                ? this.profile.credit_card.security_code
                : null,
              expiry_date: this.profile.credit_card
                ? this.profile.credit_card.expiry_date
                : null,
            },
          };
          this.signatureImageLink = this.customer.signature;
          this.signatureCompKey++;
          this.$emit('profile-updated', this.customer);
          this.$root.$emit("notify-success", this.$t("ProfileUpdated"));
        })
        .catch((err) => {
          this.$emit('profile-error', this.customer);
          this.$root.$emit("notify-error",err.response.data.error.message ? err.response.data.error.message  :this.$t("ErrorProfileUpdate"));
        })
        .finally(() => {
          setTimeout(() => {
            this.updatingProfile = false;
          }, 500);
        });
    },
    deleteDocument(id) {
      this.deletingDocument = true;
      this.$store
        .dispatch("profile/deleteDocument", id)
        .then((resp) => {
          this.$root.$emit(
            "notify-success",
            this.$t("DocumentDeletedSuccessfully")
          );
        })
        .catch((err) => {
          this.$root.$emit("notify-error", this.$t("ErrorDeletingDocument"));
        })
        .finally(() => {
          setTimeout(() => {
            this.deletingDocument = false;
          }, 500);
        });
    },
    downloadDocument(item) {
      this.downloadingDocument = true;
      this.$store
        .dispatch("profile/downloadDocument", item.id)
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$root.$emit("notify-error", this.$t("ErrorFetchingData"));
        })
        .finally(() => {
          setTimeout(() => {
            this.downloadingDocument = false;
          }, 500);
        });
    },
    onEnd() {
      this.saveSignature();
      // endable page scroll
    },
    onBegin() {
      //to fix a bug with vuetify that drawing does not show
      //https://github.com/neighborhood999/vue-signature-pad/issues/62#issuecomment-496601557
      this.$refs.signaturePad.resizeCanvas();

      // disable page scroll
    },
    undoSignature() {
      this.$refs.signaturePad.undoSignature();
      this.saveSignature();
    },
    saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if (isEmpty) {
        this.customer.signature = null;
        return;
      }

      let image = Vue.fileHelper.dataURLtoFile(
        data,
        "customer-" + this.customer.name + "-signature.png"
      );
      this.customer.signature = [image];
      this.signatureCompKey++; // to re-render signature image
    },
    deleteSignature() {
      this.updatingProfile = true;
      this.$store
        .dispatch("profile/deleteSignature")
        .then(() => {
          this.$root.$emit("notify-success", this.$t("SignatureDeleted"));
          this.signatureImageLink = null;
        })
        .catch(() => {
          this.$root.$emit("notify-error", this.$t("SignatureNotDeleted"));
        })
        .finally(() => {
          setTimeout(() => {
            this.updatingProfile = false;
          }, 500);
        });
    },
    onResidentCountryChange(countryId) {
      this.customer.state_id = null
      this.customer.city_id = null
      this.$store.dispatch("countries/getStates", { country_id: countryId });
    },
    onResidentStateChange(stateId) {
      this.customer.city_id = null
      this.$store.dispatch("countries/getCities", { state_id: stateId });
    },
  },
  async created() {
    this.updatingProfile = true;
    this.$store.dispatch("countries/get", { limit: -1 }).then((resp) => {
      this.phoneCountry = this.countries.filter(
        (c) => c.phone_code == this.profile.phone_country_code
      )[0];
    });
    
    await this.$store.dispatch("profile/show");

    this.customer = {
      ...this.profile,
      is_foreign_resident: true,
      states : this.$store.dispatch("countries/getStates",{ country_id: this.profile.country_id }),
      cities : this.$store.dispatch("countries/getCities",{ state_id: this.profile.state_id }),
      credit_card: {
        english_name: this.profile.credit_card
          ? this.profile.credit_card.english_name
          : null,
        number: this.profile.credit_card
          ? this.profile.credit_card.number
          : null,
        security_code: this.profile.credit_card
          ? this.profile.credit_card.security_code
          : null,
        expiry_date: this.profile.credit_card
          ? this.profile.credit_card.expiry_date
          : null,
      },
    };
    this.signatureImageLink = this.profile.signature;

    setTimeout(() => {
      this.updatingProfile = false;
    }, 500);
    this.dataReady = true;
  },
};
</script>

<style scoped>
.profile-form {
  position: relative;
  background-color: #EEF0EB
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.current-signature-wrapper {
  height: 250px;
  width: 350px;
  background: rgba(0,0,0, 0.04);
}
</style>