<template>
  <v-container
    loading
    fluid
    class="banner-container section-container"
    fill-height
  >
    <v-row no-gutters class="banner align-center">
      <v-col cols="12" class="content pa-10 pa-xs-5">
        <h1>{{ $t("Welcome") }}, {{ user.name || $t('Guest') }}</h1>
        <span v-if="isLoggedIn">
            <v-btn depressed color="red" text @click="logout">
            <span class="text-capitalize">{{ $t("Logout") }}</span>
            </v-btn>
        </span>
      </v-col>
      <v-col cols="12" class="bg">
        <img src="../../assets/banner2.png" alt="" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "AccountBanner",
  computed: {
      ...mapGetters({
          user:         'auth/getUser',
          isLoggedIn:   'auth/isLoggedIn'
      })
  }
};
</script>