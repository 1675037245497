<template>
    <v-card :class="{'elevation-12': solo}" :flat="!solo">
        <v-toolbar dark v-show="solo">
            <v-toolbar-title> {{ $t('LoginFormHeader') }} </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-form v-model="valid" ref="login-form" lazy-validation>
                <v-text-field
                    v-model="credentials.email"
                    prepend-icon="mdi-account"
                    name="email"
                    :label="$t('Email')"
                    type="email"
                    color="dark"
                    :rules="[filterRules.email, filterRules.required]"
                ></v-text-field>
                <v-text-field
                    v-model="credentials.password"
                    prepend-icon="mdi-lock"
                    name="password"
                    :label="$t('Password')"
                    type="password"
                    color="dark"
                    :rules="[filterRules.required]"
                ></v-text-field>
                <div class="login-actions">
                    <v-btn 
                        :loading="isLoggingIn" 
                        class="login-btn" 
                        dark 
                        color="dark"
                        @click="attemptLogin"
                    > 
                        {{ $t('Enter') }} 
                    </v-btn>
                    <a class="register-link" @click="goTo('RegisterPage')">
                        {{ $t('RegisterHere') }}
                    </a>
                </div>
                <v-divider class="mt-2"></v-divider>
                <div class="text-center pt-2">
                    <a class="forgot-password-link" @click="goTo('ForgotPasswordPage')">
                        {{ $t('ForgotPassword') }}
                    </a>
                </div>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>

import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
    name: "LoginForm",
    props: {
        solo: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            valid: false,
            isLoggingIn: false,
            credentials: {
                email: null,
                password: null,
            }
        }
    },
    computed: {
        filterRules() {
            return Vue.formHelper.filterRules();
        },
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn"
        })
    },
    methods: {
        attemptLogin() {

            if (this.isLoggedIn) {
                this.$emit('readly-logged-in');
                return;
            }

            // to validate if none of the inputs were changed
            let validation = this.$refs['login-form'].validate();

            if (this.valid && validation) {

                this.$emit('attempting-login', true);
                this.isLoggingIn = true;
                
                this.$store.dispatch("auth/login", this.credentials)
                .then((success) => {
                    this.$emit('successfull-login', true)

                    setTimeout(() => {
                        this.isLoggingIn = false;
                    }, 500);

                })
                .catch((failure) => {

                    if (failure.response) {
                        this.$root.$emit("notify-error", this.$t(failure.response.data.error.message));
                    }

                    this.$emit('failure-login', true);

                    setTimeout(() => {
                        this.isLoggingIn = false;
                    }, 500);
                });
            }

        },
        goTo(routeName) {
            var searchObj = Vue.urlHelper.extractQuery();
            if (searchObj["company_id"]) {
                this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
            } else {
                this.$router.push({name: routeName});
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.login-btn {
    margin: 0px 10px;
    padding: 5px 20px;
}
.register-link {
    text-decoration: none;
    font-weight: bold;
    @media (max-width: 767px) {
        display: block;
        margin: 10px auto;
    }
}
.login-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media(max-width: 767px) {
        display: block;
        text-align: center;
    }
}
</style>