<template>
  <div>
    <default-layout>
      <template v-slot:default="">
        <v-container fluid class="pa-0">
          <v-row no-gutters>
            <v-col cols="2">
              <account-nav-drawer 
                :style="{
                  width:'100%',
                  marginLeft:'20px'
                }"
              />
            </v-col>
            <v-col cols="12" md="10">
              <div class="content-section">
                <slot />
              </div>
            </v-col>
            
          </v-row>
        </v-container>
      </template>
    </default-layout>
  </div>
</template>

<script>
import DefaultLayout from "./DefaultLayout";
import AccountNavDrawer from "../components/Account/NavDrawer";
import PolygonBackground from "../components/Util/PolygonBackground";
import { mapGetters } from 'vuex';
export default {
  name: "AccountLayout",
  components: {
    DefaultLayout,
    AccountNavDrawer,
    PolygonBackground,
  },
  computed: {
    ...mapGetters({
      isDesktop: "isDesktop"
    })
  },
  data() {
    return {
      
    };
  },
};
</script>

<style scoped lang=scss>
  .content-section {
    background-color: #EEF0EB;
    @media (min-width: 992px) {

      margin-left:40px;
      margin-right:40px;
    }
  }
</style>